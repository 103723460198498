@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900|Libre+Baskerville:400,400i,700");
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*
  1:  1.2    =   22px
  2:  1.44   =   26px
  3:  1.728  =   31px
  4:  2.074  =   37px
  5:  2.488  =   45px
  6:  2.986  =   54px
  7:  3.583  =   65px
  8:  4.3    =   77px
  9:  5.16   =   93px
  10: 6.192  =   112px
*/
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc4845;
  --orange: #fd7e14;
  --yellow: #f3d373;
  --green: #166746;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #747b74;
  --gray-dark: #3d443d;
  --primary: #166746;
  --secondary: #c8cec8;
  --success: #166746;
  --info: #f3d373;
  --warning: #f3d373;
  --danger: #dc4845;
  --light: #dfe4df;
  --dark: #3d443d;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(21, 23, 21, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #575d57;
  text-align: left;
  background-color: #151715; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2, h4, h5, label, h6 {
  margin-top: 0;
  margin-bottom: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #1a7c54;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #219968;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #747b74;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2, h4, h5, label, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.4;
  color: inherit; }

h1, .h1 {
  font-size: 2.488rem; }

h2, .h2 {
  font-size: 2.074rem; }

h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2, .h3 {
  font-size: 1.728rem; }

h4, .h4 {
  font-size: 1.44rem; }

h5, label, .h5 {
  font-size: 1.2rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.44rem;
  font-weight: 400; }

.display-1 {
  font-size: 3.583rem;
  font-weight: 400;
  line-height: 1.4; }

.display-2 {
  font-size: 2.986rem;
  font-weight: 400;
  line-height: 1.4; }

.display-3 {
  font-size: 2.488rem;
  font-weight: 400;
  line-height: 1.4; }

.display-4 {
  font-size: 2.074rem;
  font-weight: 400;
  line-height: 1.4; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #dfe4df; }

small,
.small {
  font-size: 90%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #DEE8E2; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 90%;
  color: #747b74; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 0 solid #ffffff;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #747b74; }

code {
  font-size: 90%;
  color: #bd4147;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #ffffff;
  background-color: #212621;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 90%;
  color: #747b74; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table, .abac table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: #f6f7f6; }
  .table th, .abac table th,
  .table td,
  .abac table td {
    padding: 1rem;
    vertical-align: top;
    border-top: 2px solid #dfe4df; }
  .table thead th, .abac table thead th {
    vertical-align: bottom;
    border-bottom: 4px solid #dfe4df; }
  .table tbody + tbody, .abac table tbody + tbody {
    border-top: 4px solid #dfe4df; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered, .abac table {
  border: 2px solid #dfe4df; }
  .table-bordered th, .abac table th,
  .table-bordered td,
  .abac table td {
    border: 2px solid #dfe4df; }
  .table-bordered thead th, .abac table thead th,
  .table-bordered thead td,
  .abac table thead td {
    border-bottom-width: 4px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eef0ee; }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #eef0ee; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bed4cb; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #86b09f; }

.table-hover .table-primary:hover {
  background-color: #afcabf; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #afcabf; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f0f1f0; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #e2e6e2; }

.table-hover .table-secondary:hover {
  background-color: #e3e5e3; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e3e5e3; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bed4cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #86b09f; }

.table-hover .table-success:hover {
  background-color: #afcabf; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #afcabf; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #fcf3d8; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #f9e8b6; }

.table-hover .table-info:hover {
  background-color: #faecc0; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #faecc0; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf3d8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f9e8b6; }

.table-hover .table-warning:hover {
  background-color: #faecc0; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #faecc0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5cccb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eda09e; }

.table-hover .table-danger:hover {
  background-color: #f1b7b6; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b7b6; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f6f7f6; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #eef1ee; }

.table-hover .table-light:hover {
  background-color: #e9ebe9; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e9ebe9; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9cbc9; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9a9e9a; }

.table-hover .table-dark:hover {
  background-color: #bcbebc; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bcbebc; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #eef0ee; }

.table-hover .table-active:hover {
  background-color: #e0e5e0; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #e0e5e0; }

.table .thead-dark th, .abac table .thead-dark th {
  color: #151715;
  background-color: #212621;
  border-color: #333a33; }

.table .thead-light th, .abac table .thead-light th {
  color: #747b74;
  background-color: #ebeeeb;
  border-color: #dfe4df; }

.table-dark {
  color: #151715;
  background-color: #212621; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #333a33; }
  .table-dark.table-bordered, .abac table.table-dark {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered, .abac .table-responsive-sm > table {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered, .abac .table-responsive-md > table {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered, .abac .table-responsive-lg > table {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered, .abac .table-responsive-xl > table {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered, .abac .table-responsive > table {
    border: 0; }

.form-control, .bootstrap-tagsinput, .nav-search .search-btn, .signup-form .signup-btn {
  display: block;
  width: 100%;
  height: calc(3.05rem + 4px);
  padding: 0.9rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #575d57;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 2px solid #d8dcd8;
  border-radius: 0.25rem;
  transition: all 200ms ease-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .bootstrap-tagsinput, .nav-search .search-btn, .signup-form .signup-btn {
      transition: none; } }
  .form-control::-ms-expand, .bootstrap-tagsinput::-ms-expand, .nav-search .search-btn::-ms-expand, .signup-form .signup-btn::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .bootstrap-tagsinput:focus, .nav-search .search-btn:focus, .signup-form .signup-btn:focus {
    color: #575d57;
    background-color: #ffffff;
    border-color: #c8cec8;
    outline: 0;
    box-shadow: none, 0 0 0 3px rgba(22, 103, 70, 0.25); }
  .form-control::placeholder, .bootstrap-tagsinput::placeholder, .nav-search .search-btn::placeholder, .signup-form .signup-btn::placeholder {
    color: #b1b7b1;
    opacity: 1; }
  .form-control:disabled, .bootstrap-tagsinput:disabled, .nav-search .search-btn:disabled, .signup-form .signup-btn:disabled, .form-control[readonly], .bootstrap-tagsinput[readonly], .nav-search .search-btn[readonly], .signup-form .signup-btn[readonly] {
    background-color: #eff1ef;
    opacity: 1; }

select.form-control:focus::-ms-value, select.bootstrap-tagsinput:focus::-ms-value, .nav-search select.search-btn:focus::-ms-value, .signup-form select.signup-btn:focus::-ms-value {
  color: #575d57;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.9rem + 2px);
  padding-bottom: calc(0.9rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25; }

.col-form-label-lg {
  padding-top: calc(0.9rem + 2px);
  padding-bottom: calc(0.9rem + 2px);
  font-size: 1.2rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-bottom: 0;
  line-height: 1.25;
  color: #575d57;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(2.3125rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(3.6rem + 4px);
  padding: 0.9rem 1.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.bootstrap-tagsinput[size], .nav-search select.search-btn[size], .signup-form select.signup-btn[size], select.form-control[multiple], select.bootstrap-tagsinput[multiple], .nav-search select.search-btn[multiple], .signup-form select.signup-btn[multiple] {
  height: auto; }

textarea.form-control, textarea.bootstrap-tagsinput, .nav-search textarea.search-btn, .signup-form textarea.signup-btn {
  height: auto; }

.form-group {
  margin-bottom: 1.7rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #747b74; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 3px 8px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #ffffff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .was-validated .bootstrap-tagsinput:valid, .was-validated .nav-search .search-btn:valid, .nav-search .was-validated .search-btn:valid, .was-validated .signup-form .signup-btn:valid, .signup-form .was-validated .signup-btn:valid, .form-control.is-valid, .is-valid.bootstrap-tagsinput, .nav-search .is-valid.search-btn, .signup-form .is-valid.signup-btn {
  border-color: #28a745;
  padding-right: 3.05rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .bootstrap-tagsinput:valid:focus, .was-validated .nav-search .search-btn:valid:focus, .nav-search .was-validated .search-btn:valid:focus, .was-validated .signup-form .signup-btn:valid:focus, .signup-form .was-validated .signup-btn:valid:focus, .form-control.is-valid:focus, .is-valid.bootstrap-tagsinput:focus, .nav-search .is-valid.search-btn:focus, .signup-form .is-valid.signup-btn:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .bootstrap-tagsinput:valid ~ .valid-feedback, .was-validated .nav-search .search-btn:valid ~ .valid-feedback, .nav-search .was-validated .search-btn:valid ~ .valid-feedback, .was-validated .signup-form .signup-btn:valid ~ .valid-feedback, .signup-form .was-validated .signup-btn:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .bootstrap-tagsinput:valid ~ .valid-tooltip,
  .was-validated .nav-search .search-btn:valid ~ .valid-tooltip,
  .nav-search .was-validated .search-btn:valid ~ .valid-tooltip,
  .was-validated .signup-form .signup-btn:valid ~ .valid-tooltip,
  .signup-form .was-validated .signup-btn:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .is-valid.bootstrap-tagsinput ~ .valid-feedback, .nav-search .is-valid.search-btn ~ .valid-feedback, .signup-form .is-valid.signup-btn ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .is-valid.bootstrap-tagsinput ~ .valid-tooltip,
  .nav-search .is-valid.search-btn ~ .valid-tooltip,
  .signup-form .is-valid.signup-btn ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, .was-validated textarea.bootstrap-tagsinput:valid, .was-validated .nav-search textarea.search-btn:valid, .nav-search .was-validated textarea.search-btn:valid, .was-validated .signup-form textarea.signup-btn:valid, .signup-form .was-validated textarea.signup-btn:valid, textarea.form-control.is-valid, textarea.is-valid.bootstrap-tagsinput, .nav-search textarea.is-valid.search-btn, .signup-form textarea.is-valid.signup-btn {
  padding-right: 3.05rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: 4.0375rem;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/1.525rem 1.525rem; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 3px 8px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .was-validated .bootstrap-tagsinput:invalid, .was-validated .nav-search .search-btn:invalid, .nav-search .was-validated .search-btn:invalid, .was-validated .signup-form .signup-btn:invalid, .signup-form .was-validated .signup-btn:invalid, .form-control.is-invalid, .is-invalid.bootstrap-tagsinput, .nav-search .is-invalid.search-btn, .signup-form .is-invalid.signup-btn {
  border-color: #dc3545;
  padding-right: 3.05rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .bootstrap-tagsinput:invalid:focus, .was-validated .nav-search .search-btn:invalid:focus, .nav-search .was-validated .search-btn:invalid:focus, .was-validated .signup-form .signup-btn:invalid:focus, .signup-form .was-validated .signup-btn:invalid:focus, .form-control.is-invalid:focus, .is-invalid.bootstrap-tagsinput:focus, .nav-search .is-invalid.search-btn:focus, .signup-form .is-invalid.signup-btn:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .bootstrap-tagsinput:invalid ~ .invalid-feedback, .was-validated .nav-search .search-btn:invalid ~ .invalid-feedback, .nav-search .was-validated .search-btn:invalid ~ .invalid-feedback, .was-validated .signup-form .signup-btn:invalid ~ .invalid-feedback, .signup-form .was-validated .signup-btn:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .bootstrap-tagsinput:invalid ~ .invalid-tooltip,
  .was-validated .nav-search .search-btn:invalid ~ .invalid-tooltip,
  .nav-search .was-validated .search-btn:invalid ~ .invalid-tooltip,
  .was-validated .signup-form .signup-btn:invalid ~ .invalid-tooltip,
  .signup-form .was-validated .signup-btn:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .is-invalid.bootstrap-tagsinput ~ .invalid-feedback, .nav-search .is-invalid.search-btn ~ .invalid-feedback, .signup-form .is-invalid.signup-btn ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .is-invalid.bootstrap-tagsinput ~ .invalid-tooltip,
  .nav-search .is-invalid.search-btn ~ .invalid-tooltip,
  .signup-form .is-invalid.signup-btn ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, .was-validated textarea.bootstrap-tagsinput:invalid, .was-validated .nav-search textarea.search-btn:invalid, .nav-search .was-validated textarea.search-btn:invalid, .was-validated .signup-form textarea.signup-btn:invalid, .signup-form .was-validated textarea.signup-btn:invalid, textarea.form-control.is-invalid, textarea.is-invalid.bootstrap-tagsinput, .nav-search textarea.is-invalid.search-btn, .signup-form textarea.is-invalid.signup-btn {
  padding-right: 3.05rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: 4.0375rem;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #ffffff no-repeat center right 1.75rem/1.525rem 1.525rem; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .bootstrap-tagsinput, .form-inline .nav-search .search-btn, .nav-search .form-inline .search-btn, .form-inline .signup-form .signup-btn, .signup-form .form-inline .signup-btn {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 700;
  color: #575d57;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.9rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 200ms ease-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #575d57;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(22, 103, 70, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #166746;
  border-color: #166746; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #0f4731;
    border-color: #0d3d29; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 126, 98, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #166746;
    border-color: #166746; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #0d3d29;
    border-color: #0b3222; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 126, 98, 0.5); }

.btn-secondary {
  color: #212621;
  background-color: #c8cec8;
  border-color: #c8cec8; }
  .btn-secondary:hover {
    color: #212621;
    background-color: #b4bcb4;
    border-color: #adb6ad; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(175, 181, 175, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212621;
    background-color: #c8cec8;
    border-color: #c8cec8; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212621;
    background-color: #adb6ad;
    border-color: #a6b0a6; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(175, 181, 175, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #166746;
  border-color: #166746; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #0f4731;
    border-color: #0d3d29; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 126, 98, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #166746;
    border-color: #166746; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #0d3d29;
    border-color: #0b3222; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 126, 98, 0.5); }

.btn-info {
  color: #212621;
  background-color: #f3d373;
  border-color: #f3d373; }
  .btn-info:hover {
    color: #212621;
    background-color: #f0c850;
    border-color: #efc444; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 185, 103, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212621;
    background-color: #f3d373;
    border-color: #f3d373; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #212621;
    background-color: #efc444;
    border-color: #eec138; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 185, 103, 0.5); }

.btn-warning {
  color: #212621;
  background-color: #f3d373;
  border-color: #f3d373; }
  .btn-warning:hover {
    color: #212621;
    background-color: #f0c850;
    border-color: #efc444; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 185, 103, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212621;
    background-color: #f3d373;
    border-color: #f3d373; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212621;
    background-color: #efc444;
    border-color: #eec138; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 185, 103, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #dc4845;
  border-color: #dc4845; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #d32b28;
    border-color: #c82926; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 99, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #dc4845;
    border-color: #dc4845; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #c82926;
    border-color: #be2724; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 99, 97, 0.5); }

.btn-light {
  color: #212621;
  background-color: #dfe4df;
  border-color: #dfe4df; }
  .btn-light:hover {
    color: #212621;
    background-color: #cad2ca;
    border-color: #c3cdc3; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(195, 200, 195, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212621;
    background-color: #dfe4df;
    border-color: #dfe4df; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212621;
    background-color: #c3cdc3;
    border-color: #bcc7bc; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 200, 195, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #3d443d;
  border-color: #3d443d; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #2b302b;
    border-color: #252925; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 96, 90, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #3d443d;
    border-color: #3d443d; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #252925;
    border-color: #1f221f; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 96, 90, 0.5); }

.btn-outline-primary {
  color: #166746;
  border-color: #166746; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #166746;
    border-color: #166746; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 103, 70, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #166746;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #166746;
    border-color: #166746; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 103, 70, 0.5); }

.btn-outline-secondary {
  color: #c8cec8;
  border-color: #c8cec8; }
  .btn-outline-secondary:hover {
    color: #212621;
    background-color: #c8cec8;
    border-color: #c8cec8; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 200, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #c8cec8;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212621;
    background-color: #c8cec8;
    border-color: #c8cec8; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 206, 200, 0.5); }

.btn-outline-success {
  color: #166746;
  border-color: #166746; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #166746;
    border-color: #166746; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 103, 70, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #166746;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #166746;
    border-color: #166746; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 103, 70, 0.5); }

.btn-outline-info {
  color: #f3d373;
  border-color: #f3d373; }
  .btn-outline-info:hover {
    color: #212621;
    background-color: #f3d373;
    border-color: #f3d373; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 211, 115, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #f3d373;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212621;
    background-color: #f3d373;
    border-color: #f3d373; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 211, 115, 0.5); }

.btn-outline-warning {
  color: #f3d373;
  border-color: #f3d373; }
  .btn-outline-warning:hover {
    color: #212621;
    background-color: #f3d373;
    border-color: #f3d373; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 211, 115, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f3d373;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212621;
    background-color: #f3d373;
    border-color: #f3d373; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 211, 115, 0.5); }

.btn-outline-danger {
  color: #dc4845;
  border-color: #dc4845; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #dc4845;
    border-color: #dc4845; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 72, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc4845;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #dc4845;
    border-color: #dc4845; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 72, 69, 0.5); }

.btn-outline-light {
  color: #dfe4df;
  border-color: #dfe4df; }
  .btn-outline-light:hover {
    color: #212621;
    background-color: #dfe4df;
    border-color: #dfe4df; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 228, 223, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #dfe4df;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212621;
    background-color: #dfe4df;
    border-color: #dfe4df; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 228, 223, 0.5); }

.btn-outline-dark {
  color: #3d443d;
  border-color: #3d443d; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #3d443d;
    border-color: #3d443d; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(61, 68, 61, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3d443d;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #3d443d;
    border-color: #3d443d; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(61, 68, 61, 0.5); }

.btn-link {
  font-weight: 400;
  color: #1a7c54;
  text-decoration: none; }
  .btn-link:hover {
    color: #219968;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #689d86;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9rem 1.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(21, 23, 21, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #ebeeeb; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212621;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #151815;
    text-decoration: none;
    background-color: #F3F5F3; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #166746; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #747b74;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #747b74;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212621; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.05rem;
  padding-left: 1.05rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .input-group > .bootstrap-tagsinput, .nav-search .input-group > .search-btn, .signup-form .input-group > .signup-btn,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .input-group > .bootstrap-tagsinput + .form-control, .nav-search .input-group > .search-btn + .form-control, .signup-form .input-group > .signup-btn + .form-control, .input-group > .form-control + .bootstrap-tagsinput, .input-group > .bootstrap-tagsinput + .bootstrap-tagsinput, .nav-search .input-group > .search-btn + .bootstrap-tagsinput, .signup-form .input-group > .signup-btn + .bootstrap-tagsinput, .nav-search .input-group > .form-control + .search-btn, .nav-search .input-group > .bootstrap-tagsinput + .search-btn, .nav-search .input-group > .search-btn + .search-btn, .signup-form .nav-search .input-group > .signup-btn + .search-btn, .nav-search .signup-form .input-group > .signup-btn + .search-btn, .signup-form .input-group > .form-control + .signup-btn, .signup-form .input-group > .bootstrap-tagsinput + .signup-btn, .nav-search .signup-form .input-group > .search-btn + .signup-btn, .signup-form .nav-search .input-group > .search-btn + .signup-btn, .signup-form .input-group > .signup-btn + .signup-btn,
    .input-group > .form-control + .custom-select,
    .input-group > .bootstrap-tagsinput + .custom-select,
    .nav-search .input-group > .search-btn + .custom-select,
    .signup-form .input-group > .signup-btn + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .bootstrap-tagsinput + .custom-file,
    .nav-search .input-group > .search-btn + .custom-file,
    .signup-form .input-group > .signup-btn + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .bootstrap-tagsinput,
    .nav-search .input-group > .form-control-plaintext + .search-btn,
    .signup-form .input-group > .form-control-plaintext + .signup-btn,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .bootstrap-tagsinput,
    .nav-search .input-group > .custom-select + .search-btn,
    .signup-form .input-group > .custom-select + .signup-btn,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .bootstrap-tagsinput,
    .nav-search .input-group > .custom-file + .search-btn,
    .signup-form .input-group > .custom-file + .signup-btn,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -2px; }
  .input-group > .form-control:focus, .input-group > .bootstrap-tagsinput:focus, .nav-search .input-group > .search-btn:focus, .signup-form .input-group > .signup-btn:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .input-group > .bootstrap-tagsinput:not(:last-child), .nav-search .input-group > .search-btn:not(:last-child), .signup-form .input-group > .signup-btn:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .input-group > .bootstrap-tagsinput:not(:first-child), .nav-search .input-group > .search-btn:not(:first-child), .signup-form .input-group > .signup-btn:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -2px; }

.input-group-prepend {
  margin-right: -2px; }

.input-group-append {
  margin-left: -2px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.9rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #575d57;
  text-align: center;
  white-space: nowrap;
  background-color: #ebeeeb;
  border: 2px solid #d8dcd8;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .input-group-lg > .bootstrap-tagsinput:not(textarea), .nav-search .input-group-lg > .search-btn:not(textarea), .signup-form .input-group-lg > .signup-btn:not(textarea),
.input-group-lg > .custom-select {
  height: calc(3.6rem + 4px); }

.input-group-lg > .form-control, .input-group-lg > .bootstrap-tagsinput, .nav-search .input-group-lg > .search-btn, .signup-form .input-group-lg > .signup-btn,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.9rem 1.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .input-group-sm > .bootstrap-tagsinput:not(textarea), .nav-search .input-group-sm > .search-btn:not(textarea), .signup-form .input-group-sm > .signup-btn:not(textarea),
.input-group-sm > .custom-select {
  height: calc(2.3125rem + 4px); }

.input-group-sm > .form-control, .input-group-sm > .bootstrap-tagsinput, .nav-search .input-group-sm > .search-btn, .signup-form .input-group-sm > .signup-btn,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.7rem;
  padding-left: 1.4rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #1f9163;
    background-color: #1f9163; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #c8cec8; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #b1b6b1;
    border-color: #b1b6b1; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #747b74; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #dfe4df; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.4rem;
    left: -1.4rem;
    display: block;
    width: 0.9rem;
    height: 0.9rem;
    pointer-events: none;
    content: "";
    background-color: #c8cec8;
    border: #a4aba4 solid 2px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.4rem;
    left: -1.4rem;
    display: block;
    width: 0.9rem;
    height: 0.9rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #575d57;
  background-color: #575d57; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23ffffff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(22, 103, 70, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(22, 103, 70, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffffff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(22, 103, 70, 0.5); }

.custom-switch {
  padding-left: 2.075rem; }
  .custom-switch .custom-control-label::before {
    left: -2.075rem;
    width: 1.575rem;
    pointer-events: all;
    border-radius: 0.45rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.4rem + 4px);
    left: calc(-2.075rem + 4px);
    width: calc(0.9rem - 8px);
    height: calc(0.9rem - 8px);
    background-color: #a4aba4;
    border-radius: 0.45rem;
    transition: transform 0.15s ease-in-out, all 200ms ease-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #c8cec8;
    transform: translateX(0.675rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(22, 103, 70, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(3.05rem + 4px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #575d57;
  vertical-align: middle;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
  background-color: #ffffff;
  border: 2px solid #d8dcd8;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #b5cfc4;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(21, 23, 21, 0.075), 0 0 5px rgba(181, 207, 196, 0.5); }
    .custom-select:focus::-ms-value {
      color: #575d57;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #747b74;
    background-color: #ebeeeb; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(2.3125rem + 4px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(3.6rem + 4px);
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  padding-left: 1.4rem;
  font-size: 1.2rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(3.05rem + 4px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(3.05rem + 4px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #c8cec8;
    box-shadow: 0 0 0 0.075rem #ffffff, 0 0 0 0.2rem #166746; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #eff1ef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(3.05rem + 4px);
  padding: 0.9rem 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #575d57;
  background-color: #ffffff;
  border: 1px solid #d8dcd8;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 3.05rem;
    padding: 0.9rem 1rem;
    line-height: 1.25;
    color: #575d57;
    content: "Browse";
    background-color: #ebeeeb;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #151715, 0 0 0 0.2rem rgba(22, 103, 70, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #151715, 0 0 0 0.2rem rgba(22, 103, 70, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #151715, 0 0 0 0.2rem rgba(22, 103, 70, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #166746;
    border: 0;
    border-radius: 1rem;
    transition: all 200ms ease-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #55dba4; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dfe4df;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #166746;
    border: 0;
    border-radius: 1rem;
    transition: all 200ms ease-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #55dba4; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dfe4df;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #166746;
    border: 0;
    border-radius: 1rem;
    transition: all 200ms ease-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #55dba4; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dfe4df;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dfe4df;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #a4aba4; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #a4aba4; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #a4aba4; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: all 200ms ease-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #747b74;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #c8cec8; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #c8cec8; }
    .nav-tabs .nav-link.disabled {
      color: #747b74;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #166746;
    background-color: #F3F5F3;
    border-color: #c8cec8; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #166746; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
  font-size: 1.2rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.2rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(21, 23, 21, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(21, 23, 21, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(21, 23, 21, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(21, 23, 21, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(21, 23, 21, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(21, 23, 21, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(21, 23, 21, 0.5);
  border-color: rgba(21, 23, 21, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(21, 23, 21, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(21, 23, 21, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(21, 23, 21, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(21, 23, 21, 0.9); }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: white; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.8); }
  .navbar-dark .navbar-text a {
    color: white; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: white; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0 solid #dfe4df;
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: #fbfcfb;
  border-bottom: 0 solid #dfe4df; }
  .card-header:first-child {
    border-radius: calc(0.25rem - 0) calc(0.25rem - 0) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fbfcfb;
  border-top: 0 solid #dfe4df; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 0) calc(0.25rem - 0); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 0); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 0);
  border-top-right-radius: calc(0.25rem - 0); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 0);
  border-bottom-left-radius: calc(0.25rem - 0); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 20px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -20px;
      margin-left: -20px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 0;
        margin-left: 20px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 20px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #a4aba4;
    content: ""; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #747b74; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1a7c54;
  background-color: #ffffff;
  border: 1px solid #ddd; }
  .page-link:hover {
    z-index: 2;
    color: #219968;
    text-decoration: none;
    background-color: #ebeeeb;
    border-color: #dfe4df; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 103, 70, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #166746;
  border-color: #166746; }

.page-item.disabled .page-link {
  color: #747b74;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.3em 0.45em;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #166746; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #0d3d29; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 103, 70, 0.5); }

.badge-secondary {
  color: #212621;
  background-color: #c8cec8; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212621;
    background-color: #adb6ad; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 200, 0.5); }

.badge-success {
  color: #ffffff;
  background-color: #166746; }
  a.badge-success:hover, a.badge-success:focus {
    color: #ffffff;
    background-color: #0d3d29; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 103, 70, 0.5); }

.badge-info {
  color: #212621;
  background-color: #f3d373; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212621;
    background-color: #efc444; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 211, 115, 0.5); }

.badge-warning {
  color: #212621;
  background-color: #f3d373; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212621;
    background-color: #efc444; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 211, 115, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #dc4845; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #c82926; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 72, 69, 0.5); }

.badge-light {
  color: #212621;
  background-color: #dfe4df; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212621;
    background-color: #c3cdc3; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(223, 228, 223, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #3d443d; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: #252925; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(61, 68, 61, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #ebeeeb;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 1.25rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.25rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #16412e;
  background-color: #d0e1da;
  border-color: #bed4cb; }
  .alert-primary hr {
    border-top-color: #afcabf; }
  .alert-primary .alert-link {
    color: #091b13; }

.alert-secondary {
  color: #727672;
  background-color: #f4f5f4;
  border-color: #f0f1f0; }
  .alert-secondary hr {
    border-top-color: #e3e5e3; }
  .alert-secondary .alert-link {
    color: #595c59; }

.alert-success {
  color: #16412e;
  background-color: #d0e1da;
  border-color: #bed4cb; }
  .alert-success hr {
    border-top-color: #afcabf; }
  .alert-success .alert-link {
    color: #091b13; }

.alert-info {
  color: #887946;
  background-color: #fdf6e3;
  border-color: #fcf3d8; }
  .alert-info hr {
    border-top-color: #faecc0; }
  .alert-info .alert-link {
    color: #665b35; }

.alert-warning {
  color: #887946;
  background-color: #fdf6e3;
  border-color: #fcf3d8; }
  .alert-warning hr {
    border-top-color: #faecc0; }
  .alert-warning .alert-link {
    color: #665b35; }

.alert-danger {
  color: #7c302e;
  background-color: #f8dada;
  border-color: #f5cccb; }
  .alert-danger hr {
    border-top-color: #f1b7b6; }
  .alert-danger .alert-link {
    color: #572220; }

.alert-light {
  color: #7e827e;
  background-color: #f9faf9;
  border-color: #f6f7f6; }
  .alert-light hr {
    border-top-color: #e9ebe9; }
  .alert-light .alert-link {
    color: #656865; }

.alert-dark {
  color: #2a2e2a;
  background-color: #d8dad8;
  border-color: #c9cbc9; }
  .alert-dark hr {
    border-top-color: #bcbebc; }
  .alert-dark .alert-link {
    color: #121312; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.04688rem;
  background-color: #b5cfc4;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #1c845a;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #575d57;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #575d57;
    text-decoration: none;
    background-color: #F3F5F3; }
  .list-group-item-action:active {
    color: #575d57;
    background-color: #ebeeeb; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(21, 23, 21, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #747b74;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #166746;
    border-color: #166746; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #16412e;
  background-color: #bed4cb; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #16412e;
    background-color: #afcabf; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #16412e;
    border-color: #16412e; }

.list-group-item-secondary {
  color: #727672;
  background-color: #f0f1f0; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #727672;
    background-color: #e3e5e3; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #727672;
    border-color: #727672; }

.list-group-item-success {
  color: #16412e;
  background-color: #bed4cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #16412e;
    background-color: #afcabf; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #16412e;
    border-color: #16412e; }

.list-group-item-info {
  color: #887946;
  background-color: #fcf3d8; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #887946;
    background-color: #faecc0; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #887946;
    border-color: #887946; }

.list-group-item-warning {
  color: #887946;
  background-color: #fcf3d8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #887946;
    background-color: #faecc0; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #887946;
    border-color: #887946; }

.list-group-item-danger {
  color: #7c302e;
  background-color: #f5cccb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7c302e;
    background-color: #f1b7b6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #7c302e;
    border-color: #7c302e; }

.list-group-item-light {
  color: #7e827e;
  background-color: #f6f7f6; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7e827e;
    background-color: #e9ebe9; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #7e827e;
    border-color: #7e827e; }

.list-group-item-dark {
  color: #2a2e2a;
  background-color: #c9cbc9; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #2a2e2a;
    background-color: #bcbebc; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #2a2e2a;
    border-color: #2a2e2a; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #151715;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover {
    color: #151715;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 20px); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 20px);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 20px); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 20px);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #F3F5F3;
  background-clip: padding-box;
  border: 1px solid #dfe4df;
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #151715; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #dfe4df;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 20px;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.7; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 20px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid #dfe4df;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 60px); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 60px); }
  .modal-dialog-centered {
    min-height: calc(100% - 60px); }
    .modal-dialog-centered::before {
      height: calc(100vh - 60px); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 5px 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 5px 2.5px 0;
      border-top-color: #151715; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 5px; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 5px;
    height: 5px; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 2.5px 5px 2.5px 0;
      border-right-color: #151715; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 5px 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 2.5px 5px;
      border-bottom-color: #151715; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 5px; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 5px;
    height: 5px; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 2.5px 0 2.5px 5px;
      border-left-color: #151715; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  background-color: #151715;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(21, 23, 21, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(21, 23, 21, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(21, 23, 21, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(21, 23, 21, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(21, 23, 21, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #575d57; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 2s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  color: #F3F5F3;
  text-align: center;
  opacity: 1;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #F3F5F3;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23F3F5F3' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23F3F5F3' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 60px;
  margin-left: 60px;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-left: 10px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #F3F5F3;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 0%;
  bottom: 20px;
  left: 0%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #F3F5F3;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #166746 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0d3d29 !important; }

.bg-secondary {
  background-color: #c8cec8 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #adb6ad !important; }

.bg-success {
  background-color: #166746 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0d3d29 !important; }

.bg-info {
  background-color: #f3d373 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #efc444 !important; }

.bg-warning {
  background-color: #f3d373 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #efc444 !important; }

.bg-danger {
  background-color: #dc4845 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c82926 !important; }

.bg-light {
  background-color: #dfe4df !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c3cdc3 !important; }

.bg-dark {
  background-color: #3d443d !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #252925 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dfe4df !important; }

.border-top {
  border-top: 1px solid #dfe4df !important; }

.border-right {
  border-right: 1px solid #dfe4df !important; }

.border-bottom {
  border-bottom: 1px solid #dfe4df !important; }

.border-left {
  border-left: 1px solid #dfe4df !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #166746 !important; }

.border-secondary {
  border-color: #c8cec8 !important; }

.border-success {
  border-color: #166746 !important; }

.border-info {
  border-color: #f3d373 !important; }

.border-warning {
  border-color: #f3d373 !important; }

.border-danger {
  border-color: #dc4845 !important; }

.border-light {
  border-color: #dfe4df !important; }

.border-dark {
  border-color: #3d443d !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(21, 23, 21, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(21, 23, 21, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(21, 23, 21, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.64rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.64rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.64rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.64rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.64rem !important; }

.m-2 {
  margin: 0.8rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.8rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.8rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.8rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.8rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.2rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.2rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.2rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.2rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.2rem !important; }

.m-5 {
  margin: 1.44rem !important; }

.mt-5,
.my-5 {
  margin-top: 1.44rem !important; }

.mr-5,
.mx-5 {
  margin-right: 1.44rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 1.44rem !important; }

.ml-5,
.mx-5 {
  margin-left: 1.44rem !important; }

.m-6 {
  margin: 1.728rem !important; }

.mt-6,
.my-6 {
  margin-top: 1.728rem !important; }

.mr-6,
.mx-6 {
  margin-right: 1.728rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 1.728rem !important; }

.ml-6,
.mx-6 {
  margin-left: 1.728rem !important; }

.m-7 {
  margin: 2.074rem !important; }

.mt-7,
.my-7 {
  margin-top: 2.074rem !important; }

.mr-7,
.mx-7 {
  margin-right: 2.074rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 2.074rem !important; }

.ml-7,
.mx-7 {
  margin-left: 2.074rem !important; }

.m-8 {
  margin: 2.488rem !important; }

.mt-8,
.my-8 {
  margin-top: 2.488rem !important; }

.mr-8,
.mx-8 {
  margin-right: 2.488rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 2.488rem !important; }

.ml-8,
.mx-8 {
  margin-left: 2.488rem !important; }

.m-9 {
  margin: 2.986rem !important; }

.mt-9,
.my-9 {
  margin-top: 2.986rem !important; }

.mr-9,
.mx-9 {
  margin-right: 2.986rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 2.986rem !important; }

.ml-9,
.mx-9 {
  margin-left: 2.986rem !important; }

.m-10 {
  margin: 3.583rem !important; }

.mt-10,
.my-10 {
  margin-top: 3.583rem !important; }

.mr-10,
.mx-10 {
  margin-right: 3.583rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 3.583rem !important; }

.ml-10,
.mx-10 {
  margin-left: 3.583rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.64rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.64rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.64rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.64rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.64rem !important; }

.p-2 {
  padding: 0.8rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.8rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.8rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.8rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.8rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.2rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.2rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.2rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.2rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.2rem !important; }

.p-5 {
  padding: 1.44rem !important; }

.pt-5,
.py-5 {
  padding-top: 1.44rem !important; }

.pr-5,
.px-5 {
  padding-right: 1.44rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 1.44rem !important; }

.pl-5,
.px-5 {
  padding-left: 1.44rem !important; }

.p-6 {
  padding: 1.728rem !important; }

.pt-6,
.py-6 {
  padding-top: 1.728rem !important; }

.pr-6,
.px-6 {
  padding-right: 1.728rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 1.728rem !important; }

.pl-6,
.px-6 {
  padding-left: 1.728rem !important; }

.p-7 {
  padding: 2.074rem !important; }

.pt-7,
.py-7 {
  padding-top: 2.074rem !important; }

.pr-7,
.px-7 {
  padding-right: 2.074rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 2.074rem !important; }

.pl-7,
.px-7 {
  padding-left: 2.074rem !important; }

.p-8 {
  padding: 2.488rem !important; }

.pt-8,
.py-8 {
  padding-top: 2.488rem !important; }

.pr-8,
.px-8 {
  padding-right: 2.488rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 2.488rem !important; }

.pl-8,
.px-8 {
  padding-left: 2.488rem !important; }

.p-9 {
  padding: 2.986rem !important; }

.pt-9,
.py-9 {
  padding-top: 2.986rem !important; }

.pr-9,
.px-9 {
  padding-right: 2.986rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 2.986rem !important; }

.pl-9,
.px-9 {
  padding-left: 2.986rem !important; }

.p-10 {
  padding: 3.583rem !important; }

.pt-10,
.py-10 {
  padding-top: 3.583rem !important; }

.pr-10,
.px-10 {
  padding-right: 3.583rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 3.583rem !important; }

.pl-10,
.px-10 {
  padding-left: 3.583rem !important; }

.m-n1 {
  margin: -0.64rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.64rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.64rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.64rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.64rem !important; }

.m-n2 {
  margin: -0.8rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.8rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.8rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.8rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.8rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.2rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.2rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.2rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.2rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.2rem !important; }

.m-n5 {
  margin: -1.44rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -1.44rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -1.44rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -1.44rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -1.44rem !important; }

.m-n6 {
  margin: -1.728rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -1.728rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -1.728rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -1.728rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -1.728rem !important; }

.m-n7 {
  margin: -2.074rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -2.074rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -2.074rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -2.074rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -2.074rem !important; }

.m-n8 {
  margin: -2.488rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -2.488rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -2.488rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -2.488rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -2.488rem !important; }

.m-n9 {
  margin: -2.986rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -2.986rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -2.986rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -2.986rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -2.986rem !important; }

.m-n10 {
  margin: -3.583rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -3.583rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -3.583rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -3.583rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -3.583rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.64rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.64rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.64rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.64rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.64rem !important; }
  .m-sm-2 {
    margin: 0.8rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.8rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.8rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.8rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.8rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.2rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.2rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.2rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.2rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.2rem !important; }
  .m-sm-5 {
    margin: 1.44rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.44rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.44rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.44rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.44rem !important; }
  .m-sm-6 {
    margin: 1.728rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 1.728rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 1.728rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 1.728rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 1.728rem !important; }
  .m-sm-7 {
    margin: 2.074rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 2.074rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 2.074rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 2.074rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 2.074rem !important; }
  .m-sm-8 {
    margin: 2.488rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 2.488rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 2.488rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 2.488rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 2.488rem !important; }
  .m-sm-9 {
    margin: 2.986rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 2.986rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 2.986rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 2.986rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 2.986rem !important; }
  .m-sm-10 {
    margin: 3.583rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 3.583rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 3.583rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 3.583rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 3.583rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.64rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.64rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.64rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.64rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.64rem !important; }
  .p-sm-2 {
    padding: 0.8rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.8rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.8rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.8rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.8rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.2rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.2rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.2rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.2rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.2rem !important; }
  .p-sm-5 {
    padding: 1.44rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.44rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.44rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.44rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.44rem !important; }
  .p-sm-6 {
    padding: 1.728rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 1.728rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 1.728rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 1.728rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 1.728rem !important; }
  .p-sm-7 {
    padding: 2.074rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 2.074rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 2.074rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 2.074rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 2.074rem !important; }
  .p-sm-8 {
    padding: 2.488rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 2.488rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 2.488rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 2.488rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 2.488rem !important; }
  .p-sm-9 {
    padding: 2.986rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 2.986rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 2.986rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 2.986rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 2.986rem !important; }
  .p-sm-10 {
    padding: 3.583rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 3.583rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 3.583rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 3.583rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 3.583rem !important; }
  .m-sm-n1 {
    margin: -0.64rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.64rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.64rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.64rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.64rem !important; }
  .m-sm-n2 {
    margin: -0.8rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.8rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.8rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.8rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.8rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.2rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.2rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.2rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.2rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.2rem !important; }
  .m-sm-n5 {
    margin: -1.44rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.44rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.44rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.44rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.44rem !important; }
  .m-sm-n6 {
    margin: -1.728rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -1.728rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -1.728rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -1.728rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -1.728rem !important; }
  .m-sm-n7 {
    margin: -2.074rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -2.074rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -2.074rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -2.074rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -2.074rem !important; }
  .m-sm-n8 {
    margin: -2.488rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -2.488rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -2.488rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -2.488rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -2.488rem !important; }
  .m-sm-n9 {
    margin: -2.986rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -2.986rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -2.986rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -2.986rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -2.986rem !important; }
  .m-sm-n10 {
    margin: -3.583rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -3.583rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -3.583rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -3.583rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -3.583rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.64rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.64rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.64rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.64rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.64rem !important; }
  .m-md-2 {
    margin: 0.8rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.8rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.8rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.8rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.8rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.2rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.2rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.2rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.2rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.2rem !important; }
  .m-md-5 {
    margin: 1.44rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.44rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.44rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.44rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.44rem !important; }
  .m-md-6 {
    margin: 1.728rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 1.728rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 1.728rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.728rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 1.728rem !important; }
  .m-md-7 {
    margin: 2.074rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 2.074rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 2.074rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 2.074rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 2.074rem !important; }
  .m-md-8 {
    margin: 2.488rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 2.488rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 2.488rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 2.488rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 2.488rem !important; }
  .m-md-9 {
    margin: 2.986rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 2.986rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 2.986rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 2.986rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 2.986rem !important; }
  .m-md-10 {
    margin: 3.583rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 3.583rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 3.583rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 3.583rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 3.583rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.64rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.64rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.64rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.64rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.64rem !important; }
  .p-md-2 {
    padding: 0.8rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.8rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.8rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.8rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.8rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.2rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.2rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.2rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.2rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.2rem !important; }
  .p-md-5 {
    padding: 1.44rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.44rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.44rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.44rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.44rem !important; }
  .p-md-6 {
    padding: 1.728rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 1.728rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 1.728rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 1.728rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 1.728rem !important; }
  .p-md-7 {
    padding: 2.074rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 2.074rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 2.074rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 2.074rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 2.074rem !important; }
  .p-md-8 {
    padding: 2.488rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 2.488rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 2.488rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 2.488rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 2.488rem !important; }
  .p-md-9 {
    padding: 2.986rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 2.986rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 2.986rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 2.986rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 2.986rem !important; }
  .p-md-10 {
    padding: 3.583rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 3.583rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 3.583rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 3.583rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 3.583rem !important; }
  .m-md-n1 {
    margin: -0.64rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.64rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.64rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.64rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.64rem !important; }
  .m-md-n2 {
    margin: -0.8rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.8rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.8rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.8rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.8rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.2rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.2rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.2rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.2rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.2rem !important; }
  .m-md-n5 {
    margin: -1.44rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.44rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.44rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.44rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.44rem !important; }
  .m-md-n6 {
    margin: -1.728rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -1.728rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -1.728rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -1.728rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -1.728rem !important; }
  .m-md-n7 {
    margin: -2.074rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -2.074rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -2.074rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -2.074rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -2.074rem !important; }
  .m-md-n8 {
    margin: -2.488rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -2.488rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -2.488rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -2.488rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -2.488rem !important; }
  .m-md-n9 {
    margin: -2.986rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -2.986rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -2.986rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -2.986rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -2.986rem !important; }
  .m-md-n10 {
    margin: -3.583rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -3.583rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -3.583rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -3.583rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -3.583rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.64rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.64rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.64rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.64rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.64rem !important; }
  .m-lg-2 {
    margin: 0.8rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.8rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.8rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.8rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.8rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.2rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.2rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.2rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.2rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.2rem !important; }
  .m-lg-5 {
    margin: 1.44rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.44rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.44rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.44rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.44rem !important; }
  .m-lg-6 {
    margin: 1.728rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 1.728rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 1.728rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 1.728rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 1.728rem !important; }
  .m-lg-7 {
    margin: 2.074rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 2.074rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 2.074rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 2.074rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 2.074rem !important; }
  .m-lg-8 {
    margin: 2.488rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 2.488rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 2.488rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 2.488rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 2.488rem !important; }
  .m-lg-9 {
    margin: 2.986rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 2.986rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 2.986rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 2.986rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 2.986rem !important; }
  .m-lg-10 {
    margin: 3.583rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 3.583rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 3.583rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 3.583rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 3.583rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.64rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.64rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.64rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.64rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.64rem !important; }
  .p-lg-2 {
    padding: 0.8rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.8rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.8rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.8rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.8rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.2rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.2rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.2rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.2rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.2rem !important; }
  .p-lg-5 {
    padding: 1.44rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.44rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.44rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.44rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.44rem !important; }
  .p-lg-6 {
    padding: 1.728rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 1.728rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 1.728rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 1.728rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 1.728rem !important; }
  .p-lg-7 {
    padding: 2.074rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 2.074rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 2.074rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 2.074rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 2.074rem !important; }
  .p-lg-8 {
    padding: 2.488rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 2.488rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 2.488rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 2.488rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 2.488rem !important; }
  .p-lg-9 {
    padding: 2.986rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 2.986rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 2.986rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 2.986rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 2.986rem !important; }
  .p-lg-10 {
    padding: 3.583rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 3.583rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 3.583rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 3.583rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 3.583rem !important; }
  .m-lg-n1 {
    margin: -0.64rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.64rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.64rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.64rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.64rem !important; }
  .m-lg-n2 {
    margin: -0.8rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.8rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.8rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.8rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.8rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.2rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.2rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.2rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.2rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.2rem !important; }
  .m-lg-n5 {
    margin: -1.44rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.44rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.44rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.44rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.44rem !important; }
  .m-lg-n6 {
    margin: -1.728rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -1.728rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -1.728rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -1.728rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -1.728rem !important; }
  .m-lg-n7 {
    margin: -2.074rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -2.074rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -2.074rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -2.074rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -2.074rem !important; }
  .m-lg-n8 {
    margin: -2.488rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -2.488rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -2.488rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -2.488rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -2.488rem !important; }
  .m-lg-n9 {
    margin: -2.986rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -2.986rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -2.986rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -2.986rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -2.986rem !important; }
  .m-lg-n10 {
    margin: -3.583rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -3.583rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -3.583rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -3.583rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -3.583rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.64rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.64rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.64rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.64rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.64rem !important; }
  .m-xl-2 {
    margin: 0.8rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.8rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.8rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.8rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.8rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.2rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.2rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.2rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.2rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.2rem !important; }
  .m-xl-5 {
    margin: 1.44rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.44rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.44rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.44rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.44rem !important; }
  .m-xl-6 {
    margin: 1.728rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 1.728rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 1.728rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 1.728rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 1.728rem !important; }
  .m-xl-7 {
    margin: 2.074rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 2.074rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 2.074rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 2.074rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 2.074rem !important; }
  .m-xl-8 {
    margin: 2.488rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 2.488rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 2.488rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 2.488rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 2.488rem !important; }
  .m-xl-9 {
    margin: 2.986rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 2.986rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 2.986rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 2.986rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 2.986rem !important; }
  .m-xl-10 {
    margin: 3.583rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 3.583rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 3.583rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 3.583rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 3.583rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.64rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.64rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.64rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.64rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.64rem !important; }
  .p-xl-2 {
    padding: 0.8rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.8rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.8rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.8rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.8rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.2rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.2rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.2rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.2rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.2rem !important; }
  .p-xl-5 {
    padding: 1.44rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.44rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.44rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.44rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.44rem !important; }
  .p-xl-6 {
    padding: 1.728rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 1.728rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 1.728rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 1.728rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 1.728rem !important; }
  .p-xl-7 {
    padding: 2.074rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 2.074rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 2.074rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 2.074rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 2.074rem !important; }
  .p-xl-8 {
    padding: 2.488rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 2.488rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 2.488rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 2.488rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 2.488rem !important; }
  .p-xl-9 {
    padding: 2.986rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 2.986rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 2.986rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 2.986rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 2.986rem !important; }
  .p-xl-10 {
    padding: 3.583rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 3.583rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 3.583rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 3.583rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 3.583rem !important; }
  .m-xl-n1 {
    margin: -0.64rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.64rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.64rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.64rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.64rem !important; }
  .m-xl-n2 {
    margin: -0.8rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.8rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.8rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.8rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.8rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.2rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.2rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.2rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.2rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.2rem !important; }
  .m-xl-n5 {
    margin: -1.44rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.44rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.44rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.44rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.44rem !important; }
  .m-xl-n6 {
    margin: -1.728rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -1.728rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -1.728rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -1.728rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -1.728rem !important; }
  .m-xl-n7 {
    margin: -2.074rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -2.074rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -2.074rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -2.074rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -2.074rem !important; }
  .m-xl-n8 {
    margin: -2.488rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -2.488rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -2.488rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -2.488rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -2.488rem !important; }
  .m-xl-n9 {
    margin: -2.986rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -2.986rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -2.986rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -2.986rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -2.986rem !important; }
  .m-xl-n10 {
    margin: -3.583rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -3.583rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -3.583rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -3.583rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -3.583rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 400 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #166746 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #09281b !important; }

.text-secondary {
  color: #c8cec8 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #a0aaa0 !important; }

.text-success {
  color: #166746 !important; }

a.text-success:hover, a.text-success:focus {
  color: #09281b !important; }

.text-info {
  color: #f3d373 !important; }

a.text-info:hover, a.text-info:focus {
  color: #edbd2d !important; }

.text-warning {
  color: #f3d373 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #edbd2d !important; }

.text-danger {
  color: #dc4845 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #b32522 !important; }

.text-light {
  color: #dfe4df !important; }

a.text-light:hover, a.text-light:focus {
  color: #b6c1b6 !important; }

.text-dark {
  color: #3d443d !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #191c19 !important; }

.text-body {
  color: #575d57 !important; }

.text-muted {
  color: #747b74 !important; }

.text-black-50 {
  color: rgba(21, 23, 21, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #a4aba4;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2, h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2 {
    orphans: 3;
    widows: 3; }
  h2, h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2 {
    page-break-after: avoid; }
  @page {
    size: a4; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #151715; }
  .table, .abac table {
    border-collapse: collapse !important; }
    
    .table td,
    .abac table td, .table th, .abac table th {
      background-color: #ffffff !important; }
  .table-bordered th, .abac table th,
  .table-bordered td,
  .abac table td {
    border: 1px solid #dfe4df !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dfe4df; }
  .table .thead-dark th, .abac table .thead-dark th {
    color: inherit;
    border-color: #dfe4df; } }

.wrapper {
  background-color: #F3F5F3; }

.spacing {
  margin-bottom: 6.192rem; }

.intro {
  position: relative;
  padding-top: 6.192rem; }
  .intro .breadcrumb {
    position: absolute;
    top: 1em; }

.row.equal-height {
  display: flex;
  flex-wrap: wrap; }

.row.equal-height > [class*='col-'] {
  display: flex;
  flex-direction: column; }

.card {
  flex: 1; }

html {
  font-size: 19px; }

@media (min-width: 992px) {
  html {
    font-size: 18px; } }

.abac::after {
  display: block;
  clear: both;
  content: ""; }

.font-family-serif {
  font-family: "Libre Baskerville", Georgia, serif; }

.font-family-sans-serif {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

p {
  font-family: "Libre Baskerville", Georgia, serif; }

::selection {
  background: #DEE8E2;
  /* WebKit/Blink Browsers */ }

::-moz-selection {
  background: #DEE8E2;
  /* Gecko Browsers */ }

mark,
.mark {
  color: #575d57;
  display: inline-block; }

a, .link {
  color: #1a7c54;
  transition: all 0.2s ease-out;
  cursor: pointer;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden; }
  a:hover, .link:hover {
    color: #219968;
    text-decoration: none; }
    a:hover .logo, .link:hover .logo {
      opacity: .6; }
  a:focus, .link:focus {
    color: #219968;
    text-decoration: none; }

a:active, a:focus, .link:active, .link:focus, button:active, button:focus, .btn:active, .btn:focus {
  outline: none; }

p a, .link {
  font-weight: 700;
  border-bottom: 5px solid transparent; }
  p a:hover, .link:hover {
    border-color: #dfe4df; }

strong, b, .font-weight-bold {
  color: #3d443d; }

h1, .h1 {
  color: #212621; }
  @media (max-width: 767.98px) {
    h1, .h1 {
      word-break: break-word; } }

p + h1, figure + h1, iframe + h1, img + h1, ul + h1, ol + h1, table + h1, section + h1, .blockquote + h1, .btn + h1, .btn-link + h1 {
  margin-top: 2.074rem; }

h2, .h2 {
  color: #212621; }
  @media (max-width: 767.98px) {
    h2, .h2 {
      word-break: break-word; } }

p + h2, figure + h2, iframe + h2, img + h2, ul + h2, ol + h2, table + h2, section + h2, .blockquote + h2, .btn + h2, .btn-link + h2 {
  margin-top: 2.074rem; }

h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2, .h3 {
  color: #212621; }
  @media (max-width: 767.98px) {
    h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2, .h3 {
      word-break: break-word; } }

p + h3, #modal-error #errorMessage p + h1, #modal-error #errorMessage p + h2, figure + h3, #modal-error #errorMessage figure + h1, #modal-error #errorMessage figure + h2, iframe + h3, #modal-error #errorMessage iframe + h1, #modal-error #errorMessage iframe + h2, img + h3, #modal-error #errorMessage img + h1, #modal-error #errorMessage img + h2, ul + h3, #modal-error #errorMessage ul + h1, #modal-error #errorMessage ul + h2, ol + h3, #modal-error #errorMessage ol + h1, #modal-error #errorMessage ol + h2, table + h3, #modal-error #errorMessage table + h1, #modal-error #errorMessage table + h2, section + h3, #modal-error #errorMessage section + h1, #modal-error #errorMessage section + h2, .blockquote + h3, #modal-error #errorMessage .blockquote + h1, #modal-error #errorMessage .blockquote + h2, .btn + h3, #modal-error #errorMessage .btn + h1, #modal-error #errorMessage .btn + h2, .btn-link + h3, #modal-error #errorMessage .btn-link + h1, #modal-error #errorMessage .btn-link + h2 {
  margin-top: 2.074rem; }

h4, .h4 {
  color: #212621; }
  @media (max-width: 767.98px) {
    h4, .h4 {
      word-break: break-word; } }

p + h4, figure + h4, iframe + h4, img + h4, ul + h4, ol + h4, table + h4, section + h4, .blockquote + h4, .btn + h4, .btn-link + h4 {
  margin-top: 2.074rem; }

h5, label, .h5 {
  color: #212621; }
  @media (max-width: 767.98px) {
    h5, label, .h5 {
      word-break: break-word; } }

p + h5, p + label, figure + h5, figure + label, iframe + h5, iframe + label, img + h5, img + label, ul + h5, ul + label, ol + h5, ol + label, table + h5, table + label, section + h5, section + label, .blockquote + h5, .blockquote + label, .btn + h5, .btn + label, .btn-link + h5, .btn-link + label {
  margin-top: 2.074rem; }

h6, .h6 {
  color: #212621; }
  @media (max-width: 767.98px) {
    h6, .h6 {
      word-break: break-word; } }

p + h6, figure + h6, iframe + h6, img + h6, ul + h6, ol + h6, table + h6, section + h6, .blockquote + h6, .btn + h6, .btn-link + h6 {
  margin-top: 2.074rem; }

h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2, h4, .h3, .h4 {
  margin-bottom: 0.8rem; }

h5, label, h6, .h5, .h6 {
  margin-bottom: 0.64rem; }

@media (max-width: 767.98px) {
  h1, .h1 {
    font-size: 2.188rem; }
  h2, .h2 {
    font-size: 1.774rem; }
  h3, #modal-error #errorMessage h1, #modal-error #errorMessage h2, .h3 {
    font-size: 1.528rem; }
  h4, .h4 {
    font-size: 1.34rem; } }

.blockquote {
  background-color: #ebeeeb;
  padding: 30px 3.583rem;
  border-radius: 0.25rem;
  border-bottom: 1px solid #c8cec8; }
  .blockquote p {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.2;
    font-weight: 700;
    position: relative; }
    .blockquote p:before, .blockquote p:after {
      font-family: "Libre Baskerville", Georgia, serif;
      color: #a4aba4; }
    .blockquote p:before {
      content: '\201C';
      position: absolute;
      left: -33px;
      top: -6px;
      z-index: 1;
      font-size: 2.8rem; }
    .blockquote p:after {
      content: '\201D'; }

.blockquote-footer {
  font-family: "Libre Baskerville", Georgia, serif;
  font-style: italic; }

ul, ol {
  font-family: "Libre Baskerville", Georgia, serif; }

ul.arrowed, ul.circled, ul.contacts {
  list-style: none;
  margin-bottom: 0; }
  ul.arrowed li, ul.circled li, ul.contacts li {
    margin-bottom: 5px;
    position: relative;
    right: 8px; }

ul.arrowed li:before, ul.circled li:before {
  font-family: 'fontello';
  color: #219968;
  font-weight: normal;
  left: -20px;
  top: 5px;
  position: absolute;
  font-size: 12px; }

ul.arrowed li:before {
  content: '\e805'; }

ul.circled li:before {
  content: '\f10c'; }

.btn {
  font-size: 0.8rem;
  letter-spacing: 0.02em;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
  border: none; }
  .btn.disabled, .btn[disabled] {
    background-color: #689d86;
    border-color: #689d86; }
    .btn.disabled:hover, .btn.disabled:active, .btn.disabled:focus, .btn[disabled]:hover, .btn[disabled]:active, .btn[disabled]:focus {
      background-color: #689d86;
      border-color: #689d86; }

.btn, .btn:hover, .btn:focus, .btn:active, .btn[disabled] {
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15); }

.btn-primary:focus,
.btn-success:focus,
.btn-danger:focus,
.btn-dark:focus {
  color: #ffffff; }

.btn-secondary:focus,
.btn-info:focus,
.btn-warning:focus,
.btn-light:focus {
  color: #212621; }

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.64rem; }

.btn-lg, .btn-group-lg > .btn {
  font-size: 0.9rem; }

.box .btn,
.module .btn,
.endcta .btn,
.form .btn {
  white-space: normal; }

.btn-link {
  background: none;
  font-size: 1rem;
  font-family: "Libre Baskerville", Georgia, serif;
  font-style: italic;
  font-weight: 700;
  color: #1a7c54;
  border: none;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.2s ease-out;
  cursor: pointer;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden; }
  .btn-link:hover {
    color: #219968;
    text-decoration: none; }
  .btn-link:focus {
    color: #219968;
    text-decoration: none; }
  .btn-link:after {
    font-family: 'fontello';
    font-size: 75%;
    content: '\e805';
    position: relative;
    left: 4px;
    bottom: 2px;
    font-style: normal;
    transition: all 200ms ease-out; }
  .btn-link:hover:after {
    left: 8px; }

.sidebar {
  font-size: .9rem;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.5; }
  .sidebar p, .sidebar ul, .sidebar ol {
    font-family: inherit;
    font-size: inherit; }
  .sidebar .btn:last-child, .sidebar p:last-child {
    margin-bottom: 0; }

.sidenav .inner {
  padding-bottom: 10px !important; }

.sidenav .rootTitle a:not(:hover) {
  color: inherit; }

.sidenav ul, .sidenav ol {
  padding: 0;
  margin: 0; }

.sidenav ul {
  list-style: none; }

.sidenav li a {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 15px 5px 15px 1.728rem;
  background-color: #e5e9e5;
  border-top: 1px solid #c8cec8;
  color: #3d443d;
  font-weight: 700;
  text-transform: uppercase;
  font-size: .8rem; }
  .sidenav li a:hover {
    background-color: #dfe4df;
    color: #1a7c54; }
  .sidenav li a:before {
    content: '';
    position: absolute;
    top: 16px;
    left: 14px;
    z-index: 1; }

.sidenav li.active a {
  background-color: #575d57;
  color: #F3F5F3; }
  .sidenav li.active a:before {
    content: '\e802'; }

.sidenav .badge {
  padding: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: .7rem;
  line-height: 1.1rem;
  text-align: center; }

.box, .module, .endcta {
  display: block;
  position: relative;
  width: 100%; }

.box .inner, .module .inner {
  padding: 0.8rem; }
  @media (min-width: 576px) {
    .box .inner, .module .inner {
      padding: 1.44rem; } }

.box,
.module:not(.module-naked),
.endcta,
.nav-tabs.nav.module {
  background-color: #ebeeeb;
  border-bottom: 1px solid #c8cec8;
  border-radius: 0.25rem; }

.box.big .inner {
  padding: 2.488rem; }

.module h1, .module .h1 {
  font-size: 1.728rem; }

.module h2, .module .h2 {
  font-size: 1.728rem; }

.module h3, .module #modal-error #errorMessage h1, #modal-error #errorMessage .module h1, .module #modal-error #errorMessage h2, #modal-error #errorMessage .module h2, .module .h3 {
  font-size: 1.728rem; }

.module h4, .module .h4 {
  font-size: 1.728rem; }

.module h5, .module label, .module .h5 {
  font-size: 1.728rem; }

.module h6, .module .h6 {
  font-size: 1.728rem; }

.endcta {
  text-align: center; }
  .endcta .inner {
    padding: 2.074rem; }
  .endcta .header {
    font-size: 1.728rem;
    position: relative;
    margin-bottom: 1.2rem;
    padding-bottom: 1.2rem; }
    .endcta .header:after {
      content: '';
      width: 160px;
      height: 1px;
      background-color: #c8cec8;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -80px;
      z-index: 1; }
  .endcta .text {
    margin-bottom: 2rem;
    font-style: italic;
    line-height: 1.8;
    color: #747b74; }

.card {
  border-bottom: 1px solid #c8cec8; }

.card-text {
  font-size: .9rem; }

.jumbotron.dark, .jumbotron .bg-dark {
  color: #dfe4df; }
  .jumbotron.dark h1, .jumbotron.dark .h1, .jumbotron .bg-dark h1, .jumbotron .bg-dark .h1 {
    color: #ebeeeb; }
  .jumbotron.dark h2, .jumbotron.dark .h2, .jumbotron .bg-dark h2, .jumbotron .bg-dark .h2 {
    color: #ebeeeb; }
  .jumbotron.dark h3, .jumbotron.dark #modal-error #errorMessage h1, #modal-error #errorMessage .jumbotron.dark h1, .jumbotron.dark #modal-error #errorMessage h2, #modal-error #errorMessage .jumbotron.dark h2, .jumbotron.dark .h3, .jumbotron .bg-dark h3, .jumbotron .bg-dark #modal-error #errorMessage h1, #modal-error #errorMessage .jumbotron .bg-dark h1, .jumbotron .bg-dark #modal-error #errorMessage h2, #modal-error #errorMessage .jumbotron .bg-dark h2, .jumbotron .bg-dark .h3 {
    color: #ebeeeb; }
  .jumbotron.dark h4, .jumbotron.dark .h4, .jumbotron .bg-dark h4, .jumbotron .bg-dark .h4 {
    color: #ebeeeb; }
  .jumbotron.dark h5, .jumbotron.dark label, .jumbotron.dark .h5, .jumbotron .bg-dark h5, .jumbotron .bg-dark label, .jumbotron .bg-dark .h5 {
    color: #ebeeeb; }
  .jumbotron.dark h6, .jumbotron.dark .h6, .jumbotron .bg-dark h6, .jumbotron .bg-dark .h6 {
    color: #ebeeeb; }

.form {
  background-color: #ebeeeb;
  position: relative;
  padding: 30px;
  width: 100%;
  border-radius: 0.25rem;
  border-bottom: 1px solid #c8cec8; }
  .form.noFormBg {
    background-color: transparent;
    padding: 0;
    border: none; }

form p {
  color: #899189; }

form hr {
  margin: 2.074rem 0;
  border-color: #c8cec8; }

.req {
  color: #dc4845; }

.form-control, .bootstrap-tagsinput, .nav-search .search-btn, .signup-form .signup-btn {
  font-size: 0.875rem;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .form-control:focus, .bootstrap-tagsinput:focus, .nav-search .search-btn:focus, .signup-form .signup-btn:focus {
    box-shadow: none; }
  .form-control::-moz-placeholder, .bootstrap-tagsinput::-moz-placeholder, .nav-search .search-btn::-moz-placeholder, .signup-form .signup-btn::-moz-placeholder {
    opacity: 1;
    font-family: "Libre Baskerville", Georgia, serif;
    font-style: italic;
    color: #b1b7b1; }
  .form-control:-ms-input-placeholder, .bootstrap-tagsinput:-ms-input-placeholder, .nav-search .search-btn:-ms-input-placeholder, .signup-form .signup-btn:-ms-input-placeholder {
    font-family: "Libre Baskerville", Georgia, serif;
    font-style: italic;
    color: #b1b7b1; }
  .form-control::-webkit-input-placeholder, .bootstrap-tagsinput::-webkit-input-placeholder, .nav-search .search-btn::-webkit-input-placeholder, .signup-form .signup-btn::-webkit-input-placeholder {
    font-family: "Libre Baskerville", Georgia, serif;
    font-style: italic;
    color: #b1b7b1; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

textarea.form-control, textarea.bootstrap-tagsinput, .nav-search textarea.search-btn, .signup-form textarea.signup-btn {
  min-height: 120px;
  line-height: 1.5; }

.custom-checkbox, .custom-radio {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; }
  .custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
    font-weight: normal;
    font-size: 1rem;
    color: #575d57; }
    .custom-checkbox .custom-control-label:before, .custom-checkbox .custom-control-label:after, .custom-radio .custom-control-label:before, .custom-radio .custom-control-label:after {
      top: .25rem; }

select.form-control:not([size]):not([multiple]), select.bootstrap-tagsinput:not([size]):not([multiple]), .nav-search select.search-btn:not([size]):not([multiple]), .signup-form select.signup-btn:not([size]):not([multiple]) {
  height: calc(3.1rem); }

.btn-submit {
  width: auto; }

input[name="website"] {
  display: none; }

form .note {
  font-size: .9rem;
  color: #818881;
  margin-bottom: 5px;
  position: relative;
  bottom: 10px;
  line-height: 1.6;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

form .iconNote {
  position: relative;
  margin-bottom: 40px;
  padding-left: 50px; }
  form .iconNote p {
    margin: 0; }
  form .iconNote .icon {
    font-size: 40px;
    position: absolute;
    left: -8px;
    top: -10px;
    z-index: 1;
    opacity: 0.6; }

.bootstrap-tagsinput {
  padding: 0 0.7rem; }
  .bootstrap-tagsinput .badge, .bootstrap-tagsinput input {
    margin-bottom: 0.7rem; }
  .bootstrap-tagsinput input {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    color: #575d57 !important;
    font-size: 0.875rem; }
    .bootstrap-tagsinput input::-webkit-input-placeholder {
      font-family: "Libre Baskerville", Georgia, serif;
      font-style: italic;
      color: #b1b7b1; }
    .bootstrap-tagsinput input:focus {
      outline: none;
      border-color: none; }
  .bootstrap-tagsinput .badge {
    margin-right: 5px; }
  .bootstrap-tagsinput span[data-role='remove'] {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    width: 20px;
    text-align: center; }
    .bootstrap-tagsinput span[data-role='remove']:after {
      content: "×";
      color: inherit; }
    .bootstrap-tagsinput span[data-role='remove']:hover:after {
      color: #219968; }

.has-error *, .has-error *:focus {
  box-shadow: none !important; }

.has-error label, .has-error .control-label {
  color: #333333; }

.has-error .form-control, .has-error .bootstrap-tagsinput, .has-error .nav-search .search-btn, .nav-search .has-error .search-btn, .has-error .signup-form .signup-btn, .signup-form .has-error .signup-btn {
  border-color: #d8dcd8;
  color: #575d57; }

.has-error .form-control:required, .has-error .bootstrap-tagsinput:required, .has-error .nav-search .search-btn:required, .nav-search .has-error .search-btn:required, .has-error .signup-form .signup-btn:required, .signup-form .has-error .signup-btn:required,
.has-error .help-block {
  position: relative;
  border-color: #e49896;
  color: #d25e5b; }
  .has-error .form-control:required:focus, .has-error .bootstrap-tagsinput:required:focus, .has-error .nav-search .search-btn:required:focus, .nav-search .has-error .search-btn:required:focus, .has-error .signup-form .signup-btn:required:focus, .signup-form .has-error .signup-btn:required:focus,
  .has-error .help-block:focus {
    border-color: #e49896;
    color: #d25e5b; }

.has-error .list .help-block {
  left: 20px; }

.has-error select.form-control:required, .has-error select.bootstrap-tagsinput:required, .has-error .nav-search select.search-btn:required, .nav-search .has-error select.search-btn:required, .has-error .signup-form select.signup-btn:required, .signup-form .has-error select.signup-btn:required {
  color: #575d57; }

.has-error .help-block {
  display: block;
  padding-top: 7px;
  font-size: 0.9rem;
  line-height: 1.4; }
  .has-error .help-block:before {
    content: '\e825';
    font-family: 'Fontello';
    margin-right: 5px; }

.radiocheckStackError .help-block {
  bottom: 5px; }

.radiocheckInlineError .help-block {
  bottom: 14px; }

.backend-error {
  color: #d25e5b;
  font-size: 14px;
  position: relative;
  bottom: 28px;
  line-height: 18px; }
  .backend-error.list {
    left: 20px; }

#response ul.errors {
  margin-bottom: 25px; }

#response .btn i {
  font-size: 12px; }

.lister {
  width: 100%; }
  .lister tr td {
    padding-bottom: 10px; }
    .lister tr td:not(:last-child) {
      padding-right: 20px; }

@media (min-width: 768px) {
  .figure {
    position: relative; }
    .figure.left, .figure.right {
      width: 45%;
      top: 8px; }
      .figure.left img, .figure.left iframe, .figure.right img, .figure.right iframe {
        margin-bottom: 0; }
      .figure.left + *, .figure.right + * {
        padding-top: 0; }
    .figure.left {
      float: left;
      margin-right: 20px; }
    .figure.right {
      float: right;
      margin-left: 20px; } }

.image-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .image-grid .cell {
    width: 100%;
    padding-bottom: 15px; }
  @media (min-width: 768px) {
    .image-grid.col2 .cell {
      width: 48%; }
    .image-grid.col3 .cell {
      width: 31%; } }
  .image-grid img {
    border-radius: 3px; }
  .image-grid a img:hover {
    opacity: 0.8; }

.nav-tabs {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  text-transform: uppercase;
  font-size: .8rem; }
  .nav-tabs .nav-link {
    color: #575d57; }
    .nav-tabs .nav-link:not(.active):hover {
      background-color: #ebeeeb;
      color: #219968; }
    .nav-tabs .nav-link.active {
      cursor: default;
      border-bottom: 1px solid #F3F5F3; }
  .nav-tabs.nav {
    background-color: transparent; }

.tab-content {
  padding-top: 1.44rem; }

.carousel-inner {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; }

.carousel-item img {
  width: 100%; }

.carousel-fade .carousel-item {
  transition-duration: 2s; }

.carousel-caption {
  background-color: rgba(36, 43, 36, 0.5);
  padding: 15px 0 20px;
  bottom: 0; }
  .carousel-caption * {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #ffffff; }
  .carousel-caption h1, .carousel-caption .h1 {
    line-height: 1.1;
    font-size: 1.44rem;
    color: #F3F5F3;
    margin-bottom: 5px; }
  .carousel-caption h2, .carousel-caption .h2 {
    line-height: 1.1;
    font-size: 1.44rem;
    color: #F3F5F3;
    margin-bottom: 5px; }
  .carousel-caption h3, .carousel-caption #modal-error #errorMessage h1, #modal-error #errorMessage .carousel-caption h1, .carousel-caption #modal-error #errorMessage h2, #modal-error #errorMessage .carousel-caption h2, .carousel-caption .h3 {
    line-height: 1.1;
    font-size: 1.44rem;
    color: #F3F5F3;
    margin-bottom: 5px; }
  .carousel-caption h4, .carousel-caption .h4 {
    line-height: 1.1;
    font-size: 1.44rem;
    color: #F3F5F3;
    margin-bottom: 5px; }
  .carousel-caption h5, .carousel-caption label, .carousel-caption .h5 {
    line-height: 1.1;
    font-size: 1.44rem;
    color: #F3F5F3;
    margin-bottom: 5px; }
  .carousel-caption h6, .carousel-caption .h6 {
    line-height: 1.1;
    font-size: 1.44rem;
    color: #F3F5F3;
    margin-bottom: 5px; }
  .carousel-caption p {
    line-height: 1.4;
    text-shadow: 0px 1px 2px rgba(36, 47, 43, 0.8);
    color: #F3F5F3;
    font-size: .9rem; }

.carousel-control-next,
.carousel-control-prev {
  background-color: rgba(39, 39, 39, 0.7);
  border-radius: 0.25rem;
  width: 60px;
  height: 60px;
  top: 50%;
  margin-top: -30px; }
  .carousel-control-next:focus,
  .carousel-control-prev:focus {
    color: #F3F5F3;
    text-decoration: none;
    outline: 0;
    opacity: 1; }

.carousel-control-next {
  right: 20px; }

.carousel-control-prev {
  left: 20px; }

.carousel-control-next-icon,
.carousel-control-prev-icon {
  position: relative; }

.carousel-control-next-icon {
  left: 6px; }

.carousel-control-prev-icon {
  left: 2px; }

.carousel-indicators li {
  border-radius: 50%;
  cursor: pointer; }

.table, .abac table {
  background-color: transparent;
  color: #525852; }
  .table th, .abac table th,
  .table td,
  .abac table td {
    padding-top: .6rem;
    padding-bottom: .6rem;
    line-height: 1.3;
    border-width: 1px; }
  .table thead th, .abac table thead th {
    background-color: #ebeeeb;
    border-width: 2px;
    vertical-align: middle;
    color: #747b74;
    font-weight: normal;
    font-style: italic;
    font-family: "Libre Baskerville", Georgia, serif; }
  
  .table td,
  .abac table td, .table td ul, .abac table td ul, .table td ol, .abac table td ol {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .table.table-bordered, .abac table {
    background-color: #f6f7f6; }
  .table.equal-columns, .abac table.equal-columns {
    table-layout: fixed; }
  .table tr.divider td, .abac table tr.divider td {
    background-color: #ebeeeb;
    padding: 0.3rem;
    text-align: center;
    font-size: .8rem;
    font-weight: bold;
    text-transform: uppercase; }
  .table .btn-link, .abac table .btn-link {
    font-size: 0.9rem; }

.form .table, .form .abac table, .abac .form table {
  background-color: transparent;
  border-color: #d7ddd7; }
  .form .table th, .form .abac table th, .abac .form table th, .form .table td, .form .abac table td, .abac .form table td {
    border-color: #d7ddd7; }
  .form .table thead th, .form .abac table thead th, .abac .form table thead th {
    background-color: #e3e7e3;
    color: #6f766f; }
  .form .table.table-bordered, .form .abac table, .abac .form table {
    background-color: #f0f3f0; }
  .form .table.table-striped tbody tr:nth-of-type(odd), .form .abac table.table-striped tbody tr:nth-of-type(odd), .abac .form table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #e5e9e5; }

.close {
  cursor: pointer;
  outline: none; }
  .close:active, .close:focus {
    outline: none; }

.modal-full {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  margin: 0; }
  .modal-full .modal-content {
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: 100%; }

.modal-content {
  border: none; }

.modal .container-fluid, .modal .container {
  padding-top: 20px;
  padding-bottom: 20px; }
  .modal .container-fluid::after, .modal .container::after {
    display: block;
    clear: both;
    content: ""; }

.modal-header, .modal-footer {
  padding-top: 10px;
  padding-bottom: 10px; }

.modal-body {
  padding: 0; }

#modal-error #errorMessage {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  #modal-error #errorMessage * {
    font-family: inherit; }
  #modal-error #errorMessage pre {
    background-color: #ebeeeb;
    padding: 5px 7px;
    font-size: 0.8rem; }

.breadcrumb-item:before,
.sidenav a:before {
  font-family: 'Fontello'; }

.icon-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; }
  .icon-list li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
    border: 1px solid #dfe4df;
    padding: 20px 0;
    text-align: center;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .icon-list li:hover {
      background-color: #ebeeeb; }
  .icon-list div {
    width: 100%; }
  .icon-list .icon-example {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #212621; }
  .icon-list .icon-text {
    font-size: .8rem; }
  .icon-list .icon-class {
    font-weight: 700;
    display: inline-block;
    margin-bottom: 5px;
    color: #575d57; }
  @media (min-width: 576px) {
    .icon-list li {
      width: 33.33333%; } }
  @media (min-width: 768px) {
    .icon-list li {
      width: 25%; } }
  @media (min-width: 992px) {
    .icon-list li {
      width: 16.66667%; } }

.alert {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 10px 15px;
  border-radius: 3px;
  cursor: default; }
  .alert * {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .alert .header {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 12px; }
  .alert p:last-child {
    margin-bottom: 0; }

.alert-info {
  background-color: #ebe5d2;
  border-color: #ebdbaa; }

#cookies {
  padding: 30px 0;
  background-color: #212621;
  color: #F3F5F3;
  font-weight: bold;
  font-size: 16px; }
  #cookies * {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  #cookies p {
    margin-bottom: 10px; }
  #cookies a {
    color: #1a7c54;
    border-color: #1a7c54; }
    #cookies a:hover {
      color: #219968;
      border-color: #219968; }
  #cookies .btn {
    margin: 0; }

.alert .close {
  line-height: 28px; }

#scrollup {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #747b74;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 3px;
  opacity: .5;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1030;
  cursor: pointer;
  font-size: 1.1rem;
  color: #ffffff; }
  #scrollup:hover {
    opacity: 1;
    color: #b5cfc4; }
  #scrollup .icon {
    position: relative;
    bottom: 2px; }

.modal-open #scrollup {
  transition: none;
  opacity: 0; }

#loading.modal.show {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }

#loading .modal-content {
  background-color: transparent;
  border: none; }

#loading .icon {
  color: #ffffff;
  font-size: 1.5rem; }

body#print {
  padding-bottom: 70px; }

body#print,
body#print .table,
body#print .abac table,
.abac body#print table {
  background-color: #fff;
  color: #000;
  font-size: 16px; }
  body#print strong, body#print b,
  body#print .table strong,
  body#print .abac table strong,
  .abac body#print table strong,
  body#print .table b,
  body#print .abac table b,
  .abac body#print table b {
    color: #000; }
  body#print *,
  body#print .table *,
  body#print .abac table *,
  .abac body#print table * {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }


body#print .table,
body#print .abac table,
.abac body#print table {
  border-color: #c8cec8; }
  body#print .table thead th, body#print .abac table thead th, .abac body#print table thead th {
    border-color: #c8cec8; }
  body#print .table td, body#print .abac table td, .abac body#print table td {
    border-color: #a4aba4; }

body#print h1 {
  font-size: 36px; }

body#print h2 {
  font-size: 28px; }

body#print h3, body#print #modal-error #errorMessage h1, #modal-error #errorMessage body#print h1, body#print #modal-error #errorMessage h2, #modal-error #errorMessage body#print h2 {
  font-size: 22px; }

body#print h4 {
  font-size: 18px; }

body#print h5, body#print label {
  font-size: 16px; }

body#print h6 {
  font-size: 16px; }

.print-heading {
  display: flex;
  align-items: center;
  padding: 2rem 0 1rem; }
  .print-heading img {
    margin-right: 14px; }
  .print-heading h1 {
    margin: 0 0 0 20px;
    font-size: 2.3rem; }
  .print-heading .fairDate {
    margin: 0 0 0 20px;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700;
    font-size: 20px; }

.print-instructions {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0px 10px 0px 5px; }
  .print-instructions .icon {
    font-size: 1.6rem; }
  .print-instructions span {
    line-height: 1.1rem; }

body#print .accountDetails tr td {
  background-color: transparent;
  font-size: 0.9rem; }
  body#print .accountDetails tr td:first-child {
    width: 25%;
    font-weight: bold; }
  body#print .accountDetails tr td ul, body#print .accountDetails tr td ol {
    margin: 0;
    padding: 0 0 0 20px;
    font-family: inherit; }

body#print table.invoice tr.total td {
  font-size: 18px; }

body#print .invoiceHead, body#print .invoiceFoot {
  display: flex; }
  body#print .invoiceHead > div, body#print .invoiceFoot > div {
    width: 50%; }

body#print .invoiceFoot {
  font-size: 14px; }

@media print {
  body#print {
    padding-bottom: 0; }
  .print-instructions {
    display: none; } }

.nav-item span {
  border-bottom: 2px solid transparent;
  padding: 3px 0; }

.nav-item.active span {
  border-color: #ffffff; }

@media (max-width: 1199.98px) {
  .navbar .container {
    width: 100%;
    max-width: none; } }

.navbar-nav * {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

@media (max-width: 767.98px) {
  .navbar-nav {
    padding: 1rem 0; } }

@media (min-width: 1200px) {
  .navbar-nav {
    margin-left: -1rem; } }

.navbar .navbar-nav .nav-link {
  text-transform: uppercase; }
  @media (max-width: 767.98px) {
    .navbar .navbar-nav .nav-link {
      display: inline-block;
      font-size: 0.9rem; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .navbar .navbar-nav .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link {
      font-size: 0.7rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .navbar .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
      font-size: .9rem; } }
  @media (min-width: 1200px) {
    .navbar .navbar-nav .nav-link {
      font-size: .9rem;
      padding: 1rem 1.5rem; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar .navbar-nav.items6 .nav-link {
    font-size: .7rem; }
  .navbar .navbar-nav.items7 .nav-link {
    font-size: .55rem; }
  .navbar .navbar-nav.items8 .nav-link {
    font-size: .5rem; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar .navbar-nav.items6 .nav-link {
    font-size: .9rem; }
  .navbar .navbar-nav.items7 .nav-link {
    font-size: .75rem; }
  .navbar .navbar-nav.items8 .nav-link {
    font-size: .6rem; } }

@media (min-width: 1200px) {
  .navbar .navbar-nav.items6 .nav-link {
    font-size: .9rem; }
  .navbar .navbar-nav.items7 .nav-link {
    font-size: .75rem; }
  .navbar .navbar-nav.items8 .nav-link {
    font-size: .6rem; } }

/*//////////////////////////
      Header (Top Part)
//////////////////////////*/
.top {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 0;
  color: #151715; }
  @media (min-width: 992px) {
    .top {
      border-top: 3px solid #c8cec8; } }

.batterseaHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .batterseaHeader .abaLogos {
    display: none; }
  @media (min-width: 992px) {
    .batterseaHeader {
      flex-wrap: no-wrap;
      align-items: center; }
      .batterseaHeader .abaLogos {
        display: flex;
        align-items: center; }
        .batterseaHeader .abaLogos a {
          display: block;
          padding: 5px; }
          .batterseaHeader .abaLogos a:hover {
            opacity: 0.7; } }

.chelseaHeader {
  display: flex; }

.navbar-brand:hover {
  opacity: 0.7; }

.logo-text {
  padding-left: 10px;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.2;
  cursor: default; }
  .logo-text span {
    display: block; }
    .logo-text span.second {
      font-weight: 900; }

@media (max-width: 767.98px) {
  .nav-search {
    display: none; } }

.nav-search .fair-info {
  margin-bottom: 8px;
  font-size: .9rem; }

.nav-search .form-control, .nav-search .bootstrap-tagsinput, .nav-search .search-btn, .nav-search .signup-form .signup-btn, .signup-form .nav-search .signup-btn {
  padding-top: .4rem;
  padding-bottom: .4rem; }

.nav-search .search-btn {
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  padding-left: 10px;
  padding-right: 10px; }
  .nav-search .search-btn:hover {
    color: #1f9163; }

.nav-search .search-field {
  padding-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none; }
  .nav-search .search-field:focus {
    border-color: #d8dcd8; }

/*//////////////////////////
      Main Navigation
//////////////////////////*/
.nav {
  width: 100%;
  background-color: #0f4b33; }

.navbar .social {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0; }
  .navbar .social li.fb a:hover {
    background-color: #4668B3; }
  .navbar .social li.tw a:hover {
    background-color: #2FBBED; }
  .navbar .social li.ig a:hover {
    background-color: #E4405F; }
  .navbar .social a {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.8); }
    .navbar .social a:hover {
      color: white; }
  .navbar .social span {
    display: none; }
  @media (max-width: 767.98px) {
    .navbar .social {
      padding-bottom: 1.5rem; }
      .navbar .social li {
        margin-right: 15px; }
        .navbar .social li.fb a {
          background-color: #4668B3; }
        .navbar .social li.tw a {
          background-color: #2FBBED; }
        .navbar .social li.ig a {
          background-color: #E4405F; } }

.footer-top, .footer-bottom {
  font-size: .9rem; }
  .footer-top *, .footer-bottom * {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .footer-top ul, .footer-bottom ul {
    list-style: none;
    margin: 0;
    padding: 0; }

.footer-top {
  background-color: #212621;
  padding: 2.488rem 0 2.986rem;
  color: #747b74; }
  .footer-top h4 {
    color: #F3F5F3;
    font-size: 1rem;
    text-transform: uppercase; }
  .footer-top li {
    color: #a4aba4; }
    .footer-top li a {
      color: inherit; }
      .footer-top li a:hover {
        color: #ebeeeb; }
  @media (max-width: 767.98px) {
    .footer-top {
      text-align: center; } }

.signup-form .form-control, .signup-form .bootstrap-tagsinput, .signup-form .nav-search .search-btn, .nav-search .signup-form .search-btn, .signup-form .signup-btn {
  padding-top: .4rem;
  padding-bottom: .4rem;
  border: none; }

.signup-form .signup-btn {
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 8px;
  padding-right: 8px;
  width: auto;
  text-transform: uppercase;
  background-color: #a4aba4; }
  .signup-form .signup-btn:hover {
    background-color: #c8cec8;
    color: #1b8057; }

.signup-form .signup-field {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #dfe4df; }
  .signup-form .signup-field:focus {
    border-color: #d8dcd8; }
  .signup-form .signup-field::-moz-placeholder {
    color: #a4aba4; }
  .signup-form .signup-field:-ms-input-placeholder {
    color: #a4aba4; }
  .signup-form .signup-field::-webkit-input-placeholder {
    color: #a4aba4; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .signup-form .signup-field {
      width: 70%;
      padding-left: .7rem;
      padding-right: .7rem; } }

.footer-bottom {
  background: #151715;
  color: #747b74;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center; }
  .footer-bottom .footer-menu {
    display: flex;
    flex-wrap: wrap; }
  .footer-bottom p {
    padding: 0;
    margin: 0;
    width: 100%;
    color: inherit; }
  .footer-bottom ul {
    width: 100%; }
  .footer-bottom a {
    color: inherit;
    text-decoration: none;
    font-weight: normal;
    border: none;
    display: inline; }
    .footer-bottom a:hover {
      color: #ebeeeb; }
  @media (min-width: 992px) {
    .footer-bottom {
      text-align: left; }
      .footer-bottom .footer-menu {
        flex-wrap: nowrap; }
      .footer-bottom p {
        width: auto;
        order: 1; }
      .footer-bottom ul {
        width: auto;
        margin-left: auto;
        order: 2; }
      .footer-bottom li {
        margin-left: 1rem;
        display: inline-block; }
        .footer-bottom li.list-inline-item:not(:last-child) {
          margin-right: 5px; } }

.breadcrumb {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.breadcrumb-item {
  display: inline;
  font-size: 0.8rem;
  line-height: 1.3;
  word-break: break-word;
  color: #a4aba4; }
  .breadcrumb-item a {
    color: inherit; }
    .breadcrumb-item a:hover {
      color: #219968; }
  .breadcrumb-item.active {
    cursor: default; }
  .breadcrumb-item:before {
    font-size: .6rem;
    bottom: 2px;
    position: relative; }
  .breadcrumb-item.home .icon {
    font-size: 1.2rem;
    vertical-align: top; }
  .breadcrumb-item.home span {
    display: none; }

@media (min-width: 768px) {
  .breadcrumb-item {
    font-size: 1rem;
    line-height: 1.7; } }

.entries.list .listItem {
  padding-bottom: 2.074rem;
  margin-bottom: 2.074rem; }
  .entries.list .listItem:last-child {
    margin-bottom: 0; }

.entries.list .title {
  padding: 20px 16px 10px;
  background-color: #ebeeeb;
  border-left: 5px solid #dfe4df;
  border-radius: 3px;
  position: relative;
  left: -16px;
  margin-bottom: 20px; }
  .entries.list .title .header {
    color: #575d57;
    font-size: 1.3rem;
    line-height: 1;
    margin-bottom: 15px; }
  .entries.list .title a {
    color: inherit; }
    .entries.list .title a:hover {
      color: #219968; }

.entries.list .date {
  font-style: italic;
  position: relative;
  bottom: 5px;
  font-family: "Libre Baskerville", Georgia, serif;
  font-size: .9rem; }

.entries.list .blockquote {
  background-color: transparent;
  padding: 0 0 10px;
  margin: 0;
  border: none; }
  .entries.list .blockquote p {
    margin: 0;
    font-family: "Libre Baskerville", Georgia, serif;
    font-size: 1rem;
    color: #747b74;
    font-weight: normal;
    line-height: 1.7; }
    .entries.list .blockquote p:before {
      left: -22px;
      top: -15px; }

@media (min-width: 768px) {
  .profile.list .listItem {
    display: flex; } }

.profile.list .listItem {
  padding-top: 1.728rem;
  margin-top: 1.728rem;
  border-top: 1px solid #dfe4df; }
  .profile.list .listItem:first-child {
    padding: 0;
    margin: 0;
    border: none; }

.profile.list .title {
  font-size: 1.44rem;
  line-height: 1; }
  .profile.list .title a {
    color: inherit; }
    .profile.list .title a:hover {
      color: #219968; }

.profile.list .picContainer {
  margin-right: 30px;
  margin-bottom: 20px; }

.profile.list .pic {
  display: block;
  background-color: #ffffff;
  width: 130px;
  position: relative; }

.profile.list .default {
  padding-bottom: 98%;
  height: 0;
  border: 0.25rem solid #dfe4df; }
  .profile.list .default:before {
    content: '\e814';
    font-family: 'Fontello';
    font-size: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    color: #a4aba4;
    line-height: normal;
    margin: -40px 0 0 -27px; }

.testing tr.true td {
  background-color: #e5f2e7;
  color: #166746; }

.testing tr.false td {
  background-color: #f4e3e3;
  color: #dc4845; }

.toc {
  padding: 15px;
  background-color: #ebeeeb;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  border-bottom: 1px solid #c8cec8; }
  .toc * {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .toc ul > li {
    font-weight: 700; }
  .toc ul ul {
    padding-left: 8px;
    list-style: none; }
    .toc ul ul li {
      font-size: 0.7rem;
      font-weight: 400; }

.hpMap img {
  border: 7px solid #c8cec8; }

.hpMap .figure, .hpMap img {
  margin-bottom: 0; }

.hpWhen {
  border-bottom: 1px solid #dfe4df;
  font-family: "Libre Baskerville", Georgia, serif; }
  .hpWhen td {
    padding-top: 19px;
    padding-bottom: 19px; }

.hpWhere {
  position: relative;
  bottom: 12px; }

.hpExImage {
  border: 7px solid #1a7c54; }
  .hpExImage img {
    margin-bottom: 0; }

.partnerLogo {
  padding: 20px; }
  .partnerLogo a img:hover {
    opacity: 0.7; }

.nextPost {
  display: flex;
  border-top: 1px solid #dfe4df;
  margin-top: 1.728rem;
  padding-top: 1rem; }

.organizers {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }

.organizer {
  margin-bottom: 2.488rem;
  padding-left: 10px;
  padding-right: 10px;
  font-size: .9rem;
  line-height: 1.4; }
  .organizer .name {
    margin: 10px 0 25px; }
  .organizer .position {
    position: relative;
    bottom: 18px;
    font-style: italic;
    font-size: .8rem;
    color: #a4aba4;
    font-family: "Libre Baskerville", Georgia, serif; }
  .organizer .phone, .organizer .email, .organizer .website {
    position: relative;
    padding-left: 24px;
    margin-bottom: 5px; }
    .organizer .phone:before, .organizer .email:before, .organizer .website:before {
      font-family: 'Fontello';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
  .organizer .phone:before {
    content: '\e818'; }
  .organizer .email:before {
    content: '\e800'; }
  .organizer .website:before {
    content: '\e82a';
    left: 4px;
    font-size: 1rem; }
  @media (min-width: 576px) {
    .organizer {
      width: 50%; } }
  @media (min-width: 992px) {
    .organizer {
      width: 33.3333%; } }

/*//////////////////////////////////
        EXHIBITOR LISTING
//////////////////////////////////*/
.userSearch form {
  margin-bottom: 30px; }
  .userSearch form input[type="search"] {
    margin-bottom: 20px;
    flex-grow: 5; }
  .userSearch form .btn {
    flex-grow: 1; }

@media (min-width: 576px) {
  .userSearch form input[type="search"] {
    margin-bottom: 0;
    margin-right: 20px; } }

@media (min-width: 992px) {
  .userSearch form {
    margin-bottom: 0; } }

.sorting {
  display: flex;
  align-items: center;
  height: 54px; }
  .sorting .sortLabel {
    margin-right: 20px;
    font-weight: bold;
    text-transform: uppercase; }
  .sorting label {
    margin-bottom: 0;
    top: 3px;
    cursor: pointer; }
  @media (max-width: 991.98px) {
    .sorting {
      height: auto;
      flex-wrap: wrap;
      align-items: flex-start; }
      .sorting .sortLabel {
        width: 100%; } }

.alpha {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .alpha:after {
    content: '';
    flex: auto; }
  .alpha .letter {
    background-color: transparent;
    display: inline-block;
    border-radius: 2px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    line-height: 40px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center; }
  .alpha .anchor {
    color: #166746; }
    .alpha .anchor:hover {
      background-color: #166746;
      color: #ffffff; }
  .alpha .empty {
    color: #a4aba4;
    cursor: default; }
  @media (min-width: 992px) {
    .alpha {
      flex-wrap: nowrap; }
      .alpha:after {
        flex: none; } }

.chapter {
  font-family: "Libre Baskerville", Georgia, serif;
  color: #166746;
  cursor: default;
  margin-bottom: 0; }

.exhibitorsList {
  list-style: none;
  margin: 0;
  padding: 0; }
  .exhibitorsList li {
    border-bottom: 1px solid #dfe4df;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .exhibitorsList li > a {
      margin: 0 -10px;
      padding: 1rem 10px;
      display: flex; }
      .exhibitorsList li > a:hover {
        background-color: #ebeeeb; }
    .exhibitorsList li a > * {
      display: inline-block;
      line-height: 1.2rem; }
  .exhibitorsList .code {
    font-weight: bold;
    margin-right: 10px;
    width: 50px;
    color: #3d443d; }
  .exhibitorsList .name {
    color: #747b74; }
  .exhibitorsList .catalogue {
    margin-left: 10px;
    color: #747b74; }
  .exhibitorsList .more {
    margin-left: auto;
    padding-left: 10px; }
    .exhibitorsList .more > span {
      display: none; }

/*//////////////////////////////////
        EXHIBITOR DETAILS
//////////////////////////////////*/
.exhibitorStand {
  position: relative;
  bottom: 15px;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  font-size: 1.4rem; }

.exhibitorInfo {
  font-size: 0.8rem;
  line-height: 1.5rem; }
  .exhibitorInfo address {
    line-height: 1.3rem; }

.profilePic {
  background-color: #ffffff;
  padding: 4px;
  border-radius: 3px; }

.defaultProfile {
  background-color: #c8cec8;
  opacity: 0.7; }

.exhibitorInfo .picContainer, .exhibitorsList .picContainer {
  width: 160px;
  margin-right: 40px; }

.exhibitorInfo img, .exhibitorsList img {
  width: 100%; }

@media (max-width: 991.98px) {
  .exhibitorInfo .media, .exhibitorsList .media {
    display: block; } }

/*//////////////////////////////////
         ## LISTING
//////////////////////////////////*/
#bookFilters h3, #bookFilters #modal-error #errorMessage h1, #modal-error #errorMessage #bookFilters h1, #bookFilters #modal-error #errorMessage h2, #modal-error #errorMessage #bookFilters h2 {
  font-size: 1.2rem; }

#bookFilters input,
#bookFilters select,
#bookFilters .input-group-addon {
  padding: 0.5rem; }

#bookFilters select {
  height: 50px; }

#priceRange {
  display: flex; }
  #priceRange .input-group {
    width: 50%; }
    #priceRange .input-group.min {
      padding-right: 12px;
      position: relative; }
      #priceRange .input-group.min:after {
        content: '—';
        position: absolute;
        top: 9px;
        right: -7px;
        z-index: 1;
        font-weight: 900;
        font-size: 0.9rem; }
    #priceRange .input-group.max {
      padding-left: 12px; }

.book {
  text-align: center;
  color: #575d57; }
  .book .card-title {
    color: #212621 !important; }
  .book .card-text {
    font-size: 0.9rem; }
  .book .card-body {
    display: flex;
    flex-direction: column; }
  .book .card-footer {
    font-weight: 800; }
  .book.edit {
    box-shadow: 0 0 0 0 transparent;
    transition: box-shadow 3s ease-in-out; }
    .book.edit .card-footer {
      padding: 0; }
    .book.edit.success {
      box-shadow: 0 0 12px 1px #6db55e;
      transition: box-shadow 0s ease-in-out; }
  .book.reorder .bookImg, .book.reorder .card-body, .book.reorder .card-footer {
    cursor: move; }
  .book .relatedExhibitor {
    display: block;
    border-top: 1px solid #dfe4df;
    margin-top: 10px;
    padding-top: 10px;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.4; }

.bookImg {
  position: relative; }
  .bookImg .card-img-overlay {
    display: none; }
    .bookImg .card-img-overlay.viewBook {
      cursor: pointer; }
    .bookImg .card-img-overlay.reorderBook {
      cursor: move; }
  .bookImg:hover .card-img-overlay,
  .bookImg .card-img-overlay.loading,
  .bookImg .card-img-overlay.hover {
    background-color: rgba(21, 23, 21, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: calc(0.25rem - 0);
    border-top-right-radius: calc(0.25rem - 0);
    z-index: 1;
    width: 100%;
    height: 100%; }
    .bookImg:hover .card-img-overlay:after,
    .bookImg .card-img-overlay.loading:after,
    .bookImg .card-img-overlay.hover:after {
      font-family: 'Fontello';
      font-size: 1.8rem;
      color: #ffffff; }
  .bookImg:hover .card-img-overlay.viewBook:after {
    content: '\e828'; }
  .bookImg:hover .card-img-overlay.reorderBook:after {
    content: '\f047'; }
  .bookImg .pending {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    padding: 2px;
    background-color: rgba(239, 133, 3, 0.9);
    cursor: default;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: .7rem;
    font-weight: 700;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

/*//////////////////////////////////
         ## DETAILS
//////////////////////////////////*/
.highlightSubtitle {
  position: relative;
  bottom: 10px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .highlightSubtitle .btn {
    margin-top: 10px; }

.highlight hr {
  margin: 2.074rem 0; }

@media (min-width: 992px) {
  .highlight.tab-content > .tab-pane {
    display: block; } }

.details {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .details * {
    font-family: inherit; }
  .details.xl {
    font-size: 1.2rem; }
  .details.lg {
    font-size: 1.05rem; }
  .details.md {
    font-size: 1rem; }
  .details.sm {
    font-size: 0.9rem; }
  .details.xs {
    font-size: 0.7rem; }
  .details li, .details .item {
    display: flex;
    flex-wrap: wrap; }
  .details strong {
    margin-right: 7px; }

.pendingAlert {
  background-color: rgba(239, 133, 3, 0.9); }

.keywordsList > * {
  margin: 0 6px 6px 0; }

#bookShowcase .carousel-inner {
  margin-bottom: 1rem; }

#bookShowcase .carousel-control-next,
#bookShowcase .carousel-control-prev {
  background-color: rgba(39, 39, 39, 0.7);
  border-radius: 0.25rem;
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px; }
  #bookShowcase .carousel-control-next:focus,
  #bookShowcase .carousel-control-prev:focus {
    color: #F3F5F3;
    text-decoration: none;
    outline: 0;
    opacity: 1; }

#bookShowcase .carousel-control-next {
  right: 5px; }

#bookShowcase .carousel-control-prev {
  left: 5px; }

#bookShowcase .carousel-lightbox {
  display: none; }

@media (min-width: 992px) {
  #bookShowcase .carousel-lightbox {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: rgba(39, 39, 39, 0.7);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 0 0 0 3px;
    color: #ffffff;
    font-size: 1.5rem; }
  #bookShowcase .carousel-inner:hover .carousel-lightbox {
    display: flex; } }

#bookShowcase .carousel-control-next-icon,
#bookShowcase .carousel-control-prev-icon {
  width: 16px;
  height: 16px; }

#bookShowcase .carousel-control-next-icon {
  left: 3px; }

#bookShowcase .carousel-control-prev-icon {
  left: 2px; }

#bookShowcase .carousel-indicators {
  display: flex;
  justify-content: flex-start;
  position: static;
  left: initial;
  width: initial;
  margin: 0 -5px;
  white-space: nowrap; }
  #bookShowcase .carousel-indicators > li {
    display: inline-block;
    width: 20%;
    height: initial;
    text-indent: initial;
    margin: 0;
    padding: 0 5px; }
    #bookShowcase .carousel-indicators > li img {
      border: 1px solid transparent;
      border-radius: 3px; }
    #bookShowcase .carousel-indicators > li.active img {
      border-color: #b5cfc4; }

#modal-lightbox .modal-body {
  max-height: calc(100vh);
  overflow-y: auto;
  background-color: #151715; }

#modal-lightbox .carousel-inner {
  text-align: center; }
  #modal-lightbox .carousel-inner img {
    max-width: 100vw;
    height: auto;
    width: auto;
    max-height: calc(100vh); }

.accountHeader {
  position: relative;
  margin-bottom: 1rem;
  padding-right: 70px; }
  .accountHeader * {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .accountHeader h4 {
    margin-bottom: 0.5rem; }
  .accountHeader p:last-child {
    margin-bottom: 0; }
  .accountHeader .btn {
    padding: .4rem .8rem;
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    z-index: 1; }

.accountDetails tr td {
  font-size: 1.1rem; }

.accountDetails tr td:first-child {
  background-color: #F3F5F3;
  width: 25%;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.6; }

.accountDetails .change {
  font-size: 0.9rem; }

.accountDetails.stands td:first-child {
  width: 50%; }

.avatarContainer {
  display: flex;
  align-items: flex-start; }
  .avatarContainer .profilePic {
    margin-right: 20px; }
  .avatarContainer img {
    width: 150px; }
  .avatarContainer p {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

/*//////////////////////////////////////////
            ## HIGHLIGHTS
//////////////////////////////////////////*/
.startBooks {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-item: center;
  text-align: center;
  border: 3px dashed #dfe4df;
  padding: 3.7rem 4rem 4rem;
  border-radius: 10px; }
  .startBooks .icon {
    position: relative;
    bottom: 1rem;
    font-size: 5rem;
    line-height: 1;
    color: #c8cec8; }
  .startBooks .message {
    align-self: center;
    max-width: 500px;
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: normal;
    color: #8e948e;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .startBooks .btn {
    align-self: center;
    max-width: 300px; }

.addBookBtn {
  background-color: transparent;
  position: relative;
  display: block;
  height: 0;
  padding-top: 100%;
  border: 2px dashed #d1d8d1;
  border-radius: 10px;
  text-align: center; }
  .addBookBtn:hover {
    background-color: #ebeeeb; }
  .addBookBtn .addText {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-item: center;
    height: 100%;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: #a4aba4;
    font-size: 1.3rem; }
  .addBookBtn .icon {
    font-size: 3rem; }

#successAlert {
  height: 100px;
  margin-bottom: 2rem; }
  #successAlert .alert {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1.3rem;
    color: #166746;
    font-weight: 700; }

.edit.book .card-footer {
  display: flex;
  justify-content: space-between; }
  .edit.book .card-footer > * {
    flex-grow: 1;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 5px;
    font-size: 1.3rem;
    color: #3d443d; }
    .edit.book .card-footer > *:hover {
      background-color: #ebeeeb;
      color: #151715; }
    .edit.book .card-footer > *:not(:last-child) {
      border-right: 1px solid #dfe4df; }

.bookCategories .categoryStack {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }
  .bookCategories .categoryStack .custom-control {
    width: 33%;
    padding-right: 1rem;
    margin-right: 0; }

.bookCategories hr {
  border-color: #dfe4df;
  width: 100%;
  margin: 0.8rem 0 1rem; }

.bookCategories .custom-checkbox {
  font-size: 0.9rem; }

.bookCategories .custom-control-label {
  position: relative;
  top: 1px; }

.dz-message {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  border: 2px dashed #c8cec8;
  padding: 5rem 4rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.3rem;
  line-height: 1.4;
  color: #575d57;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.dz-message:hover,
.dz-preview:hover,
.dz-preview.ui-sortable-helper {
  background-color: #F3F5F3; }

.dz-preview {
  display: flex;
  background-color: #ebeeeb;
  align-items: center;
  padding: 10px 0;
  height: 100px;
  border-top: 1px solid #dfe4df;
  border-bottom: 1px solid transparent;
  border-radius: 4px;
  cursor: move; }
  .dz-preview:first-child [data-role='dz-name']:after {
    content: 'Album cover'; }
  .dz-preview .dz-count {
    padding-left: 1%;
    width: 7%;
    font-family: "Libre Baskerville", Georgia, serif;
    font-size: 3vw;
    font-style: italic;
    font-weight: 900;
    color: #151715; }
  .dz-preview .dz-image {
    margin-right: 20px; }
    .dz-preview .dz-image img {
      border-radius: 4px; }
  .dz-preview .dz-details {
    width: 35%;
    font-size: .9rem; }
  .dz-preview .dz-progress {
    position: relative;
    width: 40%;
    padding-right: 45px; }
  .dz-preview .dz-controls {
    display: flex;
    margin-left: auto;
    text-align: right;
    font-size: 1.7rem; }
  .dz-preview .dz-success-mark,
  .dz-preview .dz-error-mark {
    cursor: default; }
  .dz-preview .dz-success-mark {
    color: #1c845a; }
  .dz-preview .dz-error-mark {
    color: #f3d373; }
  .dz-preview .dz-remove {
    margin-left: 10px;
    color: #dc4845; }
    .dz-preview .dz-remove:hover {
      color: #d32b28; }
  .dz-preview.ui-sortable-helper {
    border-bottom: 1px solid #dfe4df; }

/*//////////////////////////////////////////
            ## FORM WIZARD
//////////////////////////////////////////*/
.form-wizard {
  padding-top: 0; }
  .form-wizard .title {
    background-color: #dfe4df;
    position: relative;
    bottom: 30px;
    margin: 0 -30px;
    padding: 20px 30px; }

.nav-wizard {
  flex-direction: column;
  font-size: .8rem !important;
  color: #3d443d;
  font-weight: 700;
  counter-reset: section; }
  .nav-wizard a {
    display: block;
    position: relative;
    padding: 15px 5px 15px 1.44rem;
    border: none;
    border-top: 1px solid #d8dcd8;
    text-transform: uppercase;
    color: inherit;
    font-weight: inherit;
    font-size: inherit; }
    .nav-wizard a:before {
      counter-increment: section;
      content: counter(section) ".";
      position: relative;
      right: 10px; }
    .nav-wizard a :hover {
      background-color: #dfe4df; }
    .nav-wizard a.active {
      cursor: default; }
    .nav-wizard a.disabled {
      cursor: default;
      background-color: #e5e9e5;
      color: #a4aba4; }
    .nav-wizard a.error:after, .nav-wizard a.completed:after {
      position: absolute;
      right: 10px;
      top: 50%;
      z-index: 1;
      margin-top: -10px;
      font-family: 'Fontello';
      font-size: 1.2rem;
      line-height: normal; }
    .nav-wizard a.completed {
      color: #166746; }
      .nav-wizard a.completed:after {
        content: '\e811';
        color: inherit; }
    .nav-wizard a.error {
      color: #dc4845 !important; }
      .nav-wizard a.error:after {
        content: '\e825';
        color: inherit; }

.wizard-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 2rem;
  padding-top: 1.7rem;
  border-top: 2px solid #dfe4df; }

.stripeField {
  cursor: text; }

.standItem p {
  margin-bottom: 0;
  color: #575d57;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.2rem; }

table.invoice tr td:last-child, table.invoice tr td.money, table.invoice tr th:last-child, table.invoice tr th.money {
  text-align: right; }

table.invoice tr td:last-child, table.invoice tr td.money {
  width: 20%;
  letter-spacing: 1.2px; }

table.invoice tr.addup td:first-child {
  text-align: right;
  font-weight: bold; }

table.invoice td.small {
  width: 8%; }

table.invoice td.br {
  background: #dfe4df;
  padding: 1px; }

table.invoice tr.total td {
  border-top: 2px solid #b5cfc4;
  background-color: #dee8e2;
  font-weight: bold;
  font-size: 1.1rem;
  color: #0f4b33; }

.purchaseControls {
  display: flex;
  align-items: center;
  background-color: #f1f3f1;
  padding: 0.8rem;
  border: 2px solid #dfe4df;
  border-radius: 0.25rem; }
  .purchaseControls.selected {
    background-color: #dee8e2;
    border-color: #b5cfc4; }
  .purchaseControls * {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .purchaseControls .btn {
    margin-left: 10px; }
  .purchaseControls .quantity {
    margin-right: 20px;
    font-weight: bold; }
  .purchaseControls .price {
    margin-right: auto;
    font-weight: bold; }

table.pricing {
  border-width: 1px; }
  table.pricing .pricelabel td {
    font-style: italic;
    color: #6d736d;
    font-family: "Libre Baskerville", Georgia, serif; }
  table.pricing td {
    height: 100%;
    width: 50%;
    padding: 0;
    vertical-align: middle !important;
    text-align: center;
    line-height: 32px; }
    table.pricing td.price {
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
      table.pricing td.price:not(.na) {
        background-color: #f9faf9;
        padding: 10px; }
        table.pricing td.price:not(.na):not(.checked):hover {
          background-color: #ffffff; }
      table.pricing td.price.checked {
        background-color: #dee8e2;
        cursor: default; }
    table.pricing td.price:not(.na):not(.checked):hover {
      cursor: pointer; }
      table.pricing td.price:not(.na):not(.checked):hover label {
        cursor: pointer; }
    table.pricing td.na {
      font-weight: 800;
      font-size: 22px;
      color: #b8bbb8; }
      table.pricing td.na span {
        display: inline-block;
        cursor: default; }
  table.pricing .listprice, table.pricing .discount, table.pricing .netprice {
    font-size: 20px; }
  table.pricing .listprice {
    text-decoration: line-through; }
  table.pricing .discount {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    color: #6c826c; }
    table.pricing .discount span {
      display: inline-block;
      position: relative; }
      table.pricing .discount span:after {
        content: attr(data-tag);
        font-size: 14px;
        display: block; }
  table.pricing .netprice {
    font-weight: 800;
    font-size: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #212621; }
  table.pricing label {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0; }

table.summary {
  background-color: #f0f3f0;
  border: 2px solid #d6dcd8;
  border-radius: 5px;
  width: 100%;
  table-layout: fixed;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 20px;
  margin-bottom: 1rem; }
  table.summary .total {
    background-color: #f6f7f6;
    font-weight: 800; }
    table.summary .total td {
      color: #212621; }
      table.summary .total td:last-child {
        font-size: 24px; }
        table.summary .total td:last-child span {
          font-weight: 400;
          font-size: 16px;
          font-family: "Libre Baskerville", Georgia, serif;
          font-style: italic;
          color: #575d57;
          position: relative;
          left: 5px;
          bottom: 3px; }
  @media (max-width: 767.98px) {
    table.summary td {
      display: block; }
    table.summary tr td:first-child {
      padding: 10px 20px 0;
      font-weight: 800; }
    table.summary tr td:last-child {
      border-bottom: 1px solid #d6dcd8;
      padding: 0 20px 10px;
      font-weight: 400; }
      table.summary tr td:last-child strong {
        font-weight: inherit; }
    table.summary .total td:last-child {
      border: none; } }
  @media (min-width: 768px) {
    table.summary td {
      vertical-align: middle;
      padding: 10px 20px;
      border-top: 1px solid #d6dcd8;
      border-left: 1px solid #d6dcd8; }
    table.summary tr td:first-child {
      width: 40%; } }

.finalPrice {
  background-color: #f0f3f0;
  border: 2px solid #d6dcd8;
  border-radius: 5px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 24px;
  margin-bottom: 50px;
  padding: 10px 20px; }
  .finalPrice .stand strong {
    font-size: 22px; }
  .finalPrice strong {
    font-weight: 800;
    color: #212621; }
  .finalPrice span {
    font-weight: 400;
    font-size: 16px;
    font-family: "Libre Baskerville", Georgia, serif;
    font-style: italic;
    color: #575d57;
    position: relative;
    bottom: 3px; }
  @media (min-width: 992px) {
    .finalPrice {
      display: table;
      table-layout: fixed;
      width: 100%;
      padding: 0; }
      .finalPrice .stand, .finalPrice .price {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding: 20px; }
        .finalPrice .stand span, .finalPrice .price span {
          display: block; } }

/* BATTERSEA Overrides
 * This file is to override any CSS that is specific to BATTERSEA. For the most
 * part, you should be editing CSS in the /src/scss/ directory and thinking about
 * both Battersea & Chelsea as a whole, using interchangeable variables like $primary
 * $primary-light, etc. Do NOT bloat this file with lazy CSS you think only pertains
 * to the site you're coding in.
 */
