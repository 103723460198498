// Variables specific to BATTERSEA

// custom gray scale
$white:             #ffffff;
$gray-100:          #F3F5F3;
$gray-200:          #ebeeeb;
$gray-300:          #dfe4df;
$gray-400:          #c8cec8;
$gray-500:          #a4aba4;
$gray-600:          #747b74;
$gray-700:          #575d57;
$gray-800:          #3d443d;
$gray-900:          #212621;
$black:             #151715;

// our theme colors
$dark-green:        #0f3526;
$forest-green:      #0f4b33;
$green:             #166746;
$light-green:       #b5cfc4;
$faded-green:       #dee8e2;
$yellow:            #f3d373;
$faded-yellow:      #f1db9c;
$red:               #dc4845;
$faded-red:         #d25e5b;

// extra colors
$blue:              #007bff;
$indigo:            #6610f2;
$purple:            #6f42c1;
$pink:              #e83e8c;
$orange:            #fd7e14;
$teal:              #20c997;
$cyan:              #17a2b8;

// social media colors
$facebook-color:    #4668B3;
$twitter-color:     #2FBBED;
$instagram-color:   #E4405F;

// BS's theme
$primary-darker:    $dark-green;
$primary-dark:      $forest-green;
$primary:           $green;
$primary-light:     $light-green;
$primary-lighter:   $faded-green;

$secondary:         $gray-400;
$success:           $green;
$info:              $yellow;
$info-light:        $faded-yellow;
$warning:           $yellow;
$warning-light:     $faded-yellow;
$danger:            $red;
$danger-light:      $faded-red;
$light:             $gray-300;
$dark:              $gray-800;