// BATTERSEA Application Form

// pricing for stands list
table.pricing {
   border-width: 1px;

   .pricelabel {
      td {
         font-style: italic;
         color: #6d736d;
         font-family: $font-family-serif;
      }
   }

   td {
      height: 100%;
      width: 50%;
      padding: 0;
      vertical-align: middle !important;
      text-align: center;
      line-height: 32px;

      &.price {
         font-family: $font-family-sans-serif;
         &:not(.na) {
            background-color: lighten($gray-100, 2%);
            padding: 10px;
            &:not(.checked):hover {
               background-color: $white;
            }
         }
         &.checked {
            background-color: $primary-lighter;
            cursor: default;
         }
      }
      &.price:not(.na):not(.checked):hover {
         cursor: pointer;
         label {
            cursor: pointer;
         }
      }

      &.na {
         font-weight: 800;
         font-size: 22px;
         color: #b8bbb8;
         span {
            display: inline-block;
            cursor: default;
         }
      }
   }

   .listprice, .discount, .netprice {
      font-size: 20px;
   }

   .listprice {
      text-decoration: line-through;
   }

   .discount {
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      color: #6c826c;
      span {

         display: inline-block;
         position: relative;
         &:after {
            content: attr(data-tag);
            font-size: 14px;
            display: block;
         }
      }
   }

   .netprice {
      font-weight: 800;
      font-size: 24px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      color: $gray-900;
   }

   label {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
   }
}

// pricing on form page
$summaryBorder: #d6dcd8;
table.summary {
   background-color: #f0f3f0;
   border: 2px solid $summaryBorder;
   border-radius: 5px;
   width: 100%;
   table-layout: fixed;
   font-family: $font-family-sans-serif;
   font-size: 20px;
   margin-bottom: 1rem;

   .total {
      background-color: #f6f7f6;
      font-weight: 800;
      td {
         color: $gray-900;
         &:last-child {
            font-size: 24px;
            span {
               font-weight: 400;
               font-size: 16px;
               font-family: $font-family-serif;
               font-style: italic;
               color: $gray-700;
               position: relative;
               left: 5px;
               bottom: 3px;
            }
         }
      }
   }

   @include media-breakpoint-down(sm) {
      td {
         display: block;
      }
      tr {
         td {
            &:first-child {
               padding: 10px 20px 0;
               font-weight: 800;
            }
            &:last-child {
               border-bottom: 1px solid $summaryBorder;
               padding: 0 20px 10px;
               font-weight: 400;
               strong {
                  font-weight: inherit;
               }
            }
         }
      }
      .total {
         td {
            &:last-child {
               border: none;
            }
         }
      }
   }
   @include media-breakpoint-up(md) {
      td {
         vertical-align: middle;
         padding: 10px 20px;
         border-top: 1px solid $summaryBorder;
         border-left: 1px solid $summaryBorder;
      }
      tr {
         td {
            &:first-child {
               width: 40%;
            }
         }
      }
   }
}

.finalPrice {
   background-color: #f0f3f0;
   border: 2px solid $summaryBorder;
   border-radius: 5px;
   font-family: $font-family-sans-serif;
   font-size: 24px;
   margin-bottom: 50px;
   padding: 10px 20px;
   .stand {
      strong {
         font-size: 22px;
      }
   }
   strong {
      font-weight: 800;
      color: $gray-900;
   }
   span {
      font-weight: 400;
      font-size: 16px;
      font-family: $font-family-serif;
      font-style: italic;
      color: $gray-700;
      position: relative;
      bottom: 3px;
   }

   @include media-breakpoint-up(lg) {
      display: table;
      table-layout: fixed;
      width: 100%;
      padding: 0;

      .stand, .price {
         display: table-cell;
         text-align: center;
         vertical-align: middle;
         padding: 20px;
         span {
            display: block;
         }
      }
      .price {

      }
   }
}